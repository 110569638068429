import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./style/App.css";
import "./style/index.css";
import mixpanel from "mixpanel-browser";
import FullTimePage from "./jobs/FullTimePage";
import InternPage from "./jobs/InternPage";
import PMPage from "./jobs/PMPage";
import SubscribePage from "./subscribe/SubscribePage";
import { onAuthChange } from "./user/User";
import { setGlobalState, GlobalStateContext } from "./state";
import AuthContext from "./utils/authContext";
import AddJob from "./jobs/AddJob";
import UserPage from "./user/UserPage";
import PostExperimentPage from "./post/PostExperimentPage"; // Import the new page
import PartnershipPage from "./partnership/PartnershipPage";
import PMInternPage from "./jobs/PMInternPage";
import DSPage from "./jobs/DSPage";
import DSInternPage from "./jobs/DSInternPage";
import PostPage from "./post/PostPage";
import ChatBot from "./ChatBot";
import ApproveJob from "./jobs/ApproveJob";
import AdminTest from "./AdminTest";

function App() {
  const getSource = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("utm_source");

    if (source) {
      return source;
    } else {
      const referrer = document.referrer;
      if (referrer.includes("facebook.com")) return "facebook";
      if (referrer.includes("reddit.com")) return "reddit";
      if (referrer.includes("discord.com")) return "discord";
      if (referrer.includes("linkedin.com")) return "linkedin";
      if (referrer.includes("google.com")) return "google";
      if (referrer.includes("teamblind.com")) return "blind";
      if (referrer.includes("producthunt.com")) return "productHunt";
      if (referrer.includes("fishbowlapp.com")) return "fishbowl";
      if (referrer.includes("levels.com")) return "levels";
      if (referrer.includes("localhost")) return "localHost";
      return "unknown";
    }
  };

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const updateState = (key, value) => {
      setGlobalState(key, value);
      if (key === "user_logedIn") {
        setLoggedIn(value);
      }
    };

    const unsubscribe = onAuthChange({
      setLoggedIn: updateState,
      setGlobalState: updateState,
    });

    const source = getSource();
    mixpanel.track("Page Viewed", { source });
    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <AuthContext.Provider value={loggedIn}>
        <Router>
          <Routes>
            <Route path="/" element={<FullTimePage />} />
            <Route
              path="/Software-Engineer-NewGrad"
              element={<FullTimePage />}
            />
            <Route
              path="/Software-Engineer-Internship"
              element={<InternPage />}
            />{" "}
            <Route
              path="/Product-Manager-Internship"
              element={<PMInternPage />}
            />
            <Route path="/Product-Manager" element={<PMPage />} />
            <Route path="/Data-Scientist" element={<DSPage />} />
            <Route
              path="/Data-Scientist-Internship"
              element={<DSInternPage />}
            />
            <Route path="/AddJob" element={<AddJob />} />
            <Route path="/User" element={<UserPage />} />
            <Route path="/JoinUs" element={<PartnershipPage />} />
            <Route path="/postTest" element={<PostExperimentPage />} />
            <Route path="/post" element={<PostPage />} />
            <Route path="/approvejob" element={<ApproveJob />} />
            <Route path="/admintest" element={<AdminTest />} />
          </Routes>
        </Router>
        <ChatBot />
      </AuthContext.Provider>
    </div>
  );
}

export default App;

import React, { useEffect, useContext } from "react";
import Header from "../layout/Header";
import Hero from "../layout/Hero";
import JobList from "./JobList";
import mixpanel from "mixpanel-browser";
import Footer from "../layout/Footer";
import AuthContext from "../utils/authContext";
import { ViewedJobProvider } from "../user/ViewedJobsContext";
import { JobType } from "../JobType";

const DSPage = () => {
  // console.log("full time page");
  const loggedIn = useContext(AuthContext);

  useEffect(() => {
    mixpanel.track("Data Page Viewed");
  }, []);

  return (
    <ViewedJobProvider>
      <div>
        <Header loggedIn={loggedIn} />
        <Hero jobType={JobType.DATA_SCIENTIST_FULL_TIME} />
        {/* <AdminTest /> */}
        <JobList
          InternPage={false}
          jobType={JobType.DATA_SCIENTIST_FULL_TIME}
        />
        <Footer />
      </div>
    </ViewedJobProvider>
  );
};

export default DSPage;

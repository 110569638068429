import React, { useState, useEffect, useRef } from "react";
import { useGlobalState } from "../state";
import mixpanel from "mixpanel-browser";
import db from "../utils/firebase";
import { getDoc, doc, collection } from "firebase/firestore";

function CompanySearch({ onSelectedCompanyChange }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [unselectedCompanies, setUnselectedCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const searchInput = useRef(null);
  const listRef = useRef(null);

  const [selectedCompany, setSelectedCompany] = useState([]);

  // const userID = useGlobalState("user_id");
  // const loggedIn = useGlobalState("user_logedIn");

  const companiesCollectionRef = collection(db, "all_companies");

  const companiesDocRef = doc(db, "all_companies", "company_list");

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        // Fetch the specific document
        const data = await getDoc(companiesDocRef);

        // Check if the document exists
        if (data.exists()) {
          const fetchedData = data.data().company_list;
          // console.log("fetchedData", fetchedData); // Debug line to check fetched data

          // Update your state here
          setUnselectedCompanies(fetchedData);
        } else {
          console.error("No such document!");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchCompanyData();
  }, []);

  useEffect(() => {
    if (onSelectedCompanyChange) {
      onSelectedCompanyChange(selectedCompany);
    }
  }, [selectedCompany, onSelectedCompanyChange]);

  useEffect(() => {
    const results = unselectedCompanies.filter(
      (company) => company.toLowerCase().includes(searchTerm.toLowerCase()) // changed to include substring matches
    );
    setFilteredCompanies(results);
    setActiveOptionIndex(-1); // reset the active option index when the filter changes
  }, [searchTerm, unselectedCompanies]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setActiveOptionIndex((prevIndex) => {
        const nextIndex = Math.min(prevIndex + 1, filteredCompanies.length - 1);
        // Scroll down
        if (listRef.current) {
          const listItems = listRef.current.querySelectorAll("li");
          if (listItems[nextIndex]) {
            listItems[nextIndex].scrollIntoView({ block: "nearest" });
          }
        }
        return nextIndex;
      });
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setActiveOptionIndex((prevIndex) => {
        const nextIndex = Math.max(prevIndex - 1, 0);
        // Scroll up
        if (listRef.current) {
          const listItems = listRef.current.querySelectorAll("li");
          if (listItems[nextIndex]) {
            listItems[nextIndex].scrollIntoView({ block: "nearest" });
          }
        }
        return nextIndex;
      });
    } else if (e.key === "Enter" && activeOptionIndex >= 0) {
      mixpanel.track("Company Search Add");
      e.preventDefault(); // Prevent default behavior
      //   setSearchTerm(filteredCompanies[activeOptionIndex].company_name);
      // console.log(filteredCompanies[activeOptionIndex]);
      setSelectedCompany([
        ...selectedCompany,
        filteredCompanies[activeOptionIndex],
      ]);
      setUnselectedCompanies(
        unselectedCompanies.filter(
          (company) => company !== filteredCompanies[activeOptionIndex]
        )
      );
      // console.log("selectedCompany", selectedCompany);
      setFilteredCompanies([]); // Hide the dropdown
      // console.log("filteredCompanies enter", filteredCompanies);
    }
  };

  const handleOptionClick = (ChosenCompany) => {
    // console.log(ChosenCompany);
    // console.log("handle option click");
    mixpanel.track("Company Search Add");

    setSelectedCompany([...selectedCompany, ChosenCompany]);
    setUnselectedCompanies(
      unselectedCompanies.filter((company) => company !== ChosenCompany)
    );
    // console.log("selectedCompany", selectedCompany);
    setFilteredCompanies([]); // Hide the dropdown
    setActiveOptionIndex(-1); // Reset active option index
  };

  const RemoveSelectedCompany = (companyToRemove) => {
    mixpanel.track("Company Search Remove");
    // console.log("onclick close");
    setSelectedCompany(
      selectedCompany.filter((company) => company !== companyToRemove)
    );
    setUnselectedCompanies([...unselectedCompanies, companyToRemove]);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="relative">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for a company..."
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              ref={searchInput}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setTimeout(() => setIsInputFocused(false), 100)}
            />
          </div>

          {isInputFocused && filteredCompanies.length > 0 && (
            <ul
              ref={listRef}
              className="absolute w-full mt-2 bg-white border rounded shadow overflow-y-auto z-10"
              style={{ maxHeight: "200px" }}
            >
              {filteredCompanies.map((company, index) => (
                <li
                  key={index}
                  className={`p-2 cursor-pointer ${
                    index === activeOptionIndex ? "bg-blue-300" : ""
                  }`}
                  onMouseDown={() => handleOptionClick(company)}
                  onMouseEnter={() => setActiveOptionIndex(index)}
                >
                  {company}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="col-span-2">
          {selectedCompany.map((company, index) => (
            <span
              key={index}
              className="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded mb-2"
            >
              {company}
              <button
                type="button"
                className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-gray-300"
                data-dismiss-target="#badge-dismiss-dark"
                aria-label="Remove"
                onClick={() => RemoveSelectedCompany(company)}
              >
                <svg
                  aria-hidden="true"
                  className="w-3.5 h-3.5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Remove badge</span>
              </button>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CompanySearch;

import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../utils/authContext";
import { useGlobalState } from "../state";
import { GetNumOfJobsData } from "./User";

const UserDashboard = () => {
  const loggedIn = useContext(AuthContext);
  const userName = useGlobalState("user_name");
  const userID = useGlobalState("user_id");
  const [numOfAppliedJobs, setNumOfAppliedJobs] = useState(0);
  const [numOfViewedJobs, setNumOfViewedJobs] = useState(0);

  const extractFirstName = (fullName) => {
    if (Array.isArray(fullName) && typeof fullName[0] === "string") {
      const nameParts = fullName[0].split(" ");
      return nameParts[0];
    }
    return "";
  };

  const userFirstName = extractFirstName(userName);

  useEffect(() => {
    const GetNum = async () => {
      if (loggedIn && userID[0]) {
        const { appliedJobs, viewedJobs } = await GetNumOfJobsData(userID[0]);

        if (appliedJobs) setNumOfAppliedJobs(appliedJobs);
        if (viewedJobs) setNumOfViewedJobs(viewedJobs);
      }
    };
    GetNum();
  }, [loggedIn, userID]);

  if (!loggedIn) {
    return null;
  }

  return (
    <div>
      <div className="max-w-4xl mx-auto my-8">
        <h4>
          <span className="bg-blue-100 text-blue-800 text-md font-medium mr-2 px-3 py-2 my-5 rounded-full">
            🎯 Hi {userFirstName}, you've viewed{" "}
            <mark className="px-2 text-white bg-blue-600 rounded dark:bg-purple-500">
              {numOfViewedJobs}
            </mark>{" "}
            and applied for{" "}
            <mark className="px-2 text-white bg-blue-600 rounded dark:bg-purple-500">
              {numOfAppliedJobs}
            </mark>{" "}
            {numOfAppliedJobs === 1 ? "job" : "jobs"} on JobPulse!
          </span>
        </h4>
      </div>
    </div>
  );
};

export default UserDashboard;

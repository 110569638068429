import { useEffect, useState } from "react";
import db from "./utils/firebase.js";
import { getDocs, collection } from "firebase/firestore";
import { Modal } from "flowbite-react";
import mixpanel from "mixpanel-browser";
import { API_BASE_URL } from "./utils/config.js";

const CompanyModal = () => {
  const [showCompanyListModal, setShowCompanyListModal] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const handleToggleModalOpen = async () => {
    await fetchCompanyData();
    setShowCompanyListModal(!showCompanyListModal);
    mixpanel.track("View Company List");
  };

  const handleToggleModalClose = () => {
    setShowCompanyListModal(false);
  };

  const fetchCompanyData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/companies`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log("Data from backend:", data);  // Debugging line

      // Since data is already an array, you can set it directly
      setCompanyList(data);
    } catch (err) {
      console.error(err);
    }
  };

  function chunkArray(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i += size) {
      const chunk = array.slice(i, i + size);
      chunked_arr.push(chunk);
    }
    return chunked_arr;
  }

  return (
    <a href="#">
      <span className="font-semibold" style={{ display: "inline-block" }}>
        <h4
          className="font-semibond text-blue-600 underline p-2"
          onClick={handleToggleModalOpen}
        >
          {companyList.length > 0 ? companyList.length : 192}
        </h4>
        <Modal onClose={handleToggleModalClose} show={showCompanyListModal}>
          <Modal.Header>💻 The Companies We Track</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <div
                className="relative overflow-x-auto shadow-md sm:rounded-lg"
                style={{ overflowY: "auto", maxHeight: "300px" }}
              >
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Company List
                      </th>
                      <th scope="col" className="px-6 py-3"></th>
                      <th scope="col" className="px-6 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {chunkArray(companyList, 3).map(
                      (companiesChunk, rowIndex) => {
                        return (
                          <tr
                            key={rowIndex}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                          >
                            {companiesChunk.map((companyName, colIndex) => (
                              <th
                                key={colIndex}
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                <div className="flex items-center">
                                  <span
                                    className={`flex w-1 h-1 rounded-full bg-gray-900 inline-block mr-3`}
                                  ></span>
                                  {companyName}
                                </div>
                              </th>
                            ))}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </span>
    </a>
  );
};
export default CompanyModal;

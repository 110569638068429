import React, { useEffect } from "react";
import Header from "../layout/Header";
import Hero from "../layout/Hero";
import PMJobList from "../jobs/PMJobList";
import mixpanel from "mixpanel-browser";
import Footer from "../layout/Footer";
import { ViewedJobProvider } from "../user/ViewedJobsContext";
import { JobType } from "../JobType";
import PostHeader from "./PostHeader";
import PostHero from "./PostHero";
import PostList from "./PostList";
import UserMilestone from "../user/UserMilestone";

const PostPage = () => {
  useEffect(() => {
    // mixpanel.track("PM Page Viewed");
  }, []);
  return (
    <ViewedJobProvider>
      <div>
        <PostHeader />
        <div className="mt-10">
          <section className="bg-center bg-no-repeat bg-blend-multiply bg-cover">
            <div className="px-4 mx-auto max-w-screen-xl text-center py-16 lg:py-16">
              <div className="grid grid-cols-12 gap-8">
                <div className="col-span-9  ">
                  {" "}
                  <PostHero />
                  <PostList />
                </div>
                <div className="col-span-3">
                  <UserMilestone />
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </ViewedJobProvider>
  );
};

export default PostPage;

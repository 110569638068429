import { setGlobalState, useGlobalState } from "../state/index.js";
import db, { auth, provider, firestore } from "../utils/firebase.js";
import {
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  getAuth,
  getIdToken,
} from "firebase/auth";

import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import { sendWelcomeEmail } from "../utils/Email.js";

import "firebase/firestore";
import { API_BASE_URL } from "../utils/config.js";

const usersCollectionRef = collection(firestore, "users");

export function GetCurrentUserEamil() {
  return useGlobalState("user_email");
}

export function GetCurrentUserName() {
  return useGlobalState("user_name");
}

export function GetCurrentUserID() {
  return useGlobalState("user_id");
}

export async function HandleLogin() {
  // console.log("handle log in");
  try {
    await signInWithPopup(auth, provider);
    mixpanel.track("Successful login");
  } catch (err) {
    console.error(err);
  }
}

export async function HandleLogOut() {
  // console.log("handle log out");
  try {
    await signOut(auth);
    window.location.reload();
  } catch (err) {
    console.error(err);
  }
}

export const isUserLoggedIn = async () => {
  const idToken = localStorage.getItem("idToken");

  if (!idToken) {
    return false;
  }

  try {
    const url = `${API_BASE_URL}/login/status/`; // Replace API_BASE_URL with your actual backend base URL
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (data && data.status === "logged in") {
        return true;
      } else {
        return false;
      }
    } else {
      console.error("Failed to fetch login status:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error fetching login status:", error);
    return false;
  }
};

export function onAuthChange({ setLoggedIn, setGlobalState }) {
  // console.log("=====onAuthChange");
  let callbackCalled = false;

  return onAuthStateChanged(auth, async (user) => {
    if (callbackCalled) return;

    if (user) {
      callbackCalled = true;
      // console.log("User logged in");
      setLoggedIn("user_logedIn", true);
      setGlobalState("user_logedIn", true);

      const idToken = await getIdToken(user, /* forceRefresh */ true);
      localStorage.setItem("idToken", idToken);
      setGlobalState("user_idtoken", idToken);

      // console.log("Token received! Token: ", idToken);

      // GetDocumentid();

      // Set the Mixpanel user identity.
      mixpanel.identify(user.uid);

      const isNew = await isNewUser(user.email);
      const signupDateValue = isNew ? new Date().toISOString() : "";

      // Set user profile properties.
      mixpanel.people.set({
        $email: user.email,
        $last_name: user.displayName ? user.displayName.split(" ")[1] : "",
        $first_name: user.displayName ? user.displayName.split(" ")[0] : "",
        // Add any necessary custom properties
        signupDate: signupDateValue,
      });

      (async () => {
        ActivateUser(
          user.displayName,
          user.email,
          user.uid,
          user.photoURL,
          setGlobalState
        );
      })();
    } else {
      // console.log("User logged out");
      setLoggedIn("user_logedIn", false);
      localStorage.removeItem("idToken");
      localStorage.removeItem("documentID");
    }
  });
}

export function JWTtoBackend(idToken, data) {
  const url = `${API_BASE_URL}/login/status`; // Here, the endpoint is hard-coded
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: idToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

async function GetDocumentid() {
  const url = `${API_BASE_URL}/uid`;
  const idToken = localStorage.getItem("idToken");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  })
    .then((response) => response.json())
    .then((data) => {
      const uid = data.uid; // here uid refers to document ID!!
      // console.log("Got Document UID, ", uid);
      localStorage.setItem("documentID", uid);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export async function isNewUser(email) {
  const userQuery = query(usersCollectionRef, where("email", "==", email));
  const querySnapshot = await getDocs(userQuery);
  const userExists = querySnapshot.size > 0;
  if (userExists) {
    // console.log("user exist");
    const userDoc = querySnapshot.docs[0];
    const userId = userDoc.id;
    setGlobalState("user_id", userId);
    // console.log("isNewUser", userId);
  }
  return !userExists;
}

export async function ActivateUser(
  UserName,
  UserEmail,
  UserID, // this id is authentication id
  photoURL,
  setGlobalStateCallback
) {
  const newUser = await isNewUser(UserEmail);

  if (newUser) {
    const UserId = await InitUser(UserName, UserEmail, UserID);
    setGlobalStateCallback("user_id", UserId);
    sendWelcomeEmail(UserEmail, UserName);
  }
  setGlobalStateCallback("user_name", UserName);
  setGlobalStateCallback("user_email", UserEmail);
  setGlobalStateCallback("user_logedIn", true);
  setGlobalStateCallback("user_uid", UserID);
  setGlobalStateCallback("user_photoURL", photoURL);

  localStorage.setItem("uid", UserID);
  localStorage.setItem("user_logedIn", true);
}

export async function InitUser(Username, UserEmail, UserID) {
  try {
    const userDocRef = doc(usersCollectionRef, UserID); // Use UserID as the document ID
    await setDoc(userDocRef, {
      name: Username,
      email: UserEmail,
      applied_jobs: [],
    });

    // console.log("initit user", UserID);

    return UserID; // Return the provided UserID as the document ID
  } catch (err) {
    console.error(err);
  }
}

export async function IsThisJobsApplied(UserID, JobID) {
  const userRef = doc(db, "users", UserID);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    const appliedJobs = userData.applied_jobs;

    let applied;
    if (appliedJobs) {
      applied = appliedJobs.includes(JobID);
      // console.log("this job applied", applied);
      return applied;
    }
  }

  return false;
}

export async function IsThisJobsViewed(UserID, JobID) {
  const userRef = doc(db, "users", UserID);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    const viewedJobs = userData.viewed_jobs;

    let viewed;
    if (viewedJobs) {
      viewed = viewedJobs.includes(JobID);
      return viewed;
    }
  }

  return false;
}

export async function MarkAJobAsApplied(UserID, JobID) {
  const idToken = localStorage.getItem("idToken"); // Get ID token from local storage
  const uid = localStorage.getItem("uid"); // Get uid from local storage

  if (UserID && JobID) {
    try {
      const url = `${API_BASE_URL}/applied_jobs?uid=${uid}`; // Define the API endpoint
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken, // Use the ID token as a Bearer token for Authorization
        },
        body: JSON.stringify({ job_id: JobID }), // Send the JobID in the request body
      });

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          // console.log("Successfully marked job as applied.");
          // Perform any additional actions here, e.g., updating local state
          // setLastAppliedJob(JobID);
        } else {
          console.error("Failed to mark job as applied:", data);
        }
      } else {
        console.error(
          "Failed to mark job as applied, HTTP status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error marking job as applied:", error);
    }
  }
}

export async function MarkAJobAsUnApplied(UserID, JobID) {
  const idToken = localStorage.getItem("idToken"); // Get ID token from local storage
  const uid = localStorage.getItem("uid"); // Get uid from local storage

  if (UserID && JobID) {
    try {
      const url = `${API_BASE_URL}/applied_jobs?uid=${uid}`; // Define the API endpoint
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken, // Use the ID token as a Bearer token for Authorization
        },
        body: JSON.stringify({ job_id: JobID }), // Send the JobID in the request body
      });

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          // console.log("Successfully removed job from applied list.");
          // Perform any additional actions here, e.g., updating local state
        } else {
          console.error("Failed to remove job from applied list:", data);
        }
      } else {
        console.error(
          "Failed to remove job from applied list, HTTP status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error removing job from applied list:", error);
    }
  }
}

export async function ViewThisJob(userID, JobID) {
  const idToken = localStorage.getItem("idToken"); // Get ID token from local storage
  const uid = localStorage.getItem("uid"); // Get uid from local storage

  if (userID && JobID) {
    mixpanel.track("Login View Job");

    try {
      const url = `${API_BASE_URL}/viewed_jobs?uid=${uid}`; // Add uid as a query parameter
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken, // Use the ID token as a Bearer token for Authorization
        },
        body: JSON.stringify({ job_id: JobID }), // Send the JobID in the request body
      });

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          // console.log("Successfully updated viewed jobs.");
          // You can perform any additional actions here, e.g., updating local state
          // setLastViewedJob(JobID);
        } else {
          console.error("Failed to update viewed jobs:", data);
        }
      } else {
        console.error(
          "Failed to update viewed jobs, HTTP status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error updating viewed jobs:", error);
    }
  } else {
    mixpanel.track("Unlogin View Job");
  }
}

export async function IgnoreThisJob(userID, JobID) {
  const idToken = localStorage.getItem("idToken"); // Get ID token from local storage
  const uid = localStorage.getItem("uid"); // Get uid from local storage

  if (userID && JobID) {
    mixpanel.track("Login Ignore Job");

    try {
      const url = `${API_BASE_URL}/ignored_jobs?uid=${uid}`; // Change endpoint to 'ignored_jobs'
      // console.log(url);
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken, // Use the ID token as a Bearer token for Authorization
        },
        body: JSON.stringify({ job_id: JobID }), // Send the JobID in the request body
      });

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          // console.log("Successfully updated ignored jobs.");
          // You can perform any additional actions here, e.g., updating local state
          // setLastIgnoredJob(JobID);
        } else {
          console.error("Failed to update ignored jobs:", data);
        }
      } else {
        console.error(
          "Failed to update ignored jobs, HTTP status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error updating ignored jobs:", error);
    }
  } else {
    mixpanel.track("Unlogin Ignore Job");
  }
}

export async function UndoIgnoreAJob(UserID, JobID) {
  const idToken = localStorage.getItem("idToken"); // Get ID token from local storage
  const uid = localStorage.getItem("uid"); // Get uid from local storage

  if (UserID && JobID) {
    try {
      const url = `${API_BASE_URL}/ignored_jobs?uid=${uid}`; // Point to the 'ignored_jobs' endpoint
      const response = await fetch(url, {
        method: "DELETE", // Use the DELETE method
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken, // Use the ID token as a Bearer token for Authorization
        },
        body: JSON.stringify({ job_id: JobID }), // Send the JobID in the request body
      });

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          // console.log("Successfully removed job from ignored list.");
          // Perform any additional actions here, e.g., updating local state
        } else {
          console.error("Failed to remove job from ignored list:", data);
        }
      } else {
        console.error(
          "Failed to remove job from ignored list, HTTP status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error removing job from ignored list:", error);
    }
  }
}

export async function IsThisJobIgnored(UserID, JobID) {
  const userRef = doc(db, "users", UserID); // Get the user's document reference
  const docSnap = await getDoc(userRef); // Fetch the document snapshot

  if (docSnap.exists()) {
    const userData = docSnap.data(); // Extract data from the snapshot
    const ignoredJobs = userData.ignored_jobs; // Extract the ignored_jobs array

    if (ignoredJobs) {
      return ignoredJobs.includes(JobID); // Check if the job ID exists in the ignored_jobs array
    }
  }

  return false; // Return false by default if the job isn't ignored or if there's no such field
}

export async function GetNumOfJobsData(UserID) {
  if (!UserID) {
    console.error("Invalid UserID");
    return {
      appliedJobs: 0,
      viewedJobs: 0,
    };
  }

  try {
    const userRef = doc(db, "users", UserID);

    const userDoc = await getDoc(userRef);

    if (userDoc.exists) {
      const userData = userDoc.data();
      const numOfAppliedJobs = userData.applied_jobs?.length || 0;
      const numOfViewedJobs = userData.viewed_jobs?.length || 0;

      return {
        appliedJobs: numOfAppliedJobs,
        viewedJobs: numOfViewedJobs,
      };
    } else {
      console.error("User not found in the database");
      return {
        appliedJobs: 0,
        viewedJobs: 0,
      };
    }
  } catch (error) {
    console.error("Error fetching jobs data:", error);
    return {
      appliedJobs: 0,
      viewedJobs: 0,
    };
  }
}

export async function UpdateSubscribePreferences(UserID, subscriptionData) {
  if (UserID) {
    const userDoc = doc(db, "users", UserID);

    try {
      const docSnap = await getDoc(userDoc);
      const timestamp = Date.now(); // Use current timestamp as the key.

      if (docSnap.exists()) {
        const oldData = docSnap.data();
        const subscriptions = oldData.subscriptions || {};

        // Add a new entry to the subscriptions object.
        subscriptions[timestamp] = subscriptionData;

        await updateDoc(userDoc, {
          subscriptions: subscriptions,
        });
      } else {
        await setDoc(userDoc, {
          subscriptions: {
            [timestamp]: subscriptionData,
          },
        });
      }
    } catch (err) {
      console.error("Error updating document:", err);
    }
  } else {
    console.error("Login before subscribing");
  }
}

export async function clearSubscribePreferences(UserID) {
  if (UserID) {
    const userDoc = doc(db, "users", UserID);

    try {
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        // Set the subscriptions object to an empty object.
        await updateDoc(userDoc, {
          subscriptions: {},
        });
      }
    } catch (err) {
      console.error("Error updating document:", err);
    }
  } else {
    console.error("Login before subscribing");
  }
}

export async function getSubscribePreferences(UserID) {
  if (UserID) {
    const userDoc = doc(db, "users", UserID);

    try {
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        const oldData = docSnap.data();
        const subscriptions = oldData.subscriptions || {};

        // Log subscriptions to console
        // console.log(subscriptions);

        // Return the subscriptions
        return subscriptions;
      } else {
        console.error("Document doesn't exist");
        return null;
      }
    } catch (err) {
      console.error("Error reading document:", err);
    }
  } else {
    console.error("Login before subscribing");
  }
}

export async function SubscribeToWeeklyJobAlert(UserID) {
  console.log(UserID);
  if (UserID) {
    const userDoc = doc(db, "users", UserID);

    try {
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        const oldData = docSnap.data();
        const subscriptions = oldData.subscriptions || {};

        // Add or update the weeklyreport_subscribed field in the subscriptions object.
        subscriptions["weeklyreport_subscribed"] = true;

        await updateDoc(userDoc, {
          subscriptions: subscriptions,
        });

        // console.log("successfully subscribed to weekly job alert");
      } else {
        await setDoc(userDoc, {
          subscriptions: {
            weeklyreport_subscribed: true,
          },
        });
      }
    } catch (err) {
      console.error("Error updating document:", err);
    }
  } else {
    console.log("Login before subscribing");
  }
}

export async function IsUserSubscribedToWeeklyReport(UserID) {
  const userRef = doc(db, "users", UserID);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    const subscriptions = userData.subscriptions;

    if (
      subscriptions &&
      subscriptions.hasOwnProperty("weeklyreport_subscribed")
    ) {
      return subscriptions["weeklyreport_subscribed"] === true;
    }
  }

  return false;
}

export async function getUserNameById(userId) {
  try {
    const userDocRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.name;
    } else {
      console.error("No such user found with the provided ID.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}

export async function getUserEmailById(userId) {
  try {
    const userDocRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.email;
    } else {
      console.error("No such user found with the provided ID.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}

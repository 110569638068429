import { useEffect, useState } from "react";
import db from "../utils/firebase";
import { collection, addDoc, getDoc, doc } from "firebase/firestore";
import { HandleLogin } from "../user/User";
import mixpanel from "mixpanel-browser";
import {
  UpdateSubscribePreferences,
  clearSubscribePreferences,
  getSubscribePreferences,
} from "../user/User";
import { useGlobalState } from "../state";
import google_logo from "../img/Google_logo.png";
import CompanySelection from "../search/CompanySelection";

const companiesDocRef = doc(db, "all_companies", "company_list");

const JobAlertWindow = () => {
  const [userID] = useGlobalState("user_id");

  const [companies, setCompanies] = useState([]);
  const [isSWEFulltimeChecked, setIsSWEFulltimeChecked] = useState(false);
  const [isPMFulltimeChecked, setIsPMFulltimeChecked] = useState(false);
  const [isSWEInternChecked, setIsSWEInternChecked] = useState(false);
  const [isPMInternChecked, setIsPMInternChecked] = useState(false);
  const [FullTimeData, setFullTimeData] = useState(null);
  const [InternData, setInternData] = useState(null);
  const [PMFulltimeData, setPMFullTimeData] = useState(null);
  const [PMInterneData, setPMInternData] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [SWEFTSelectedCompanies, setSWEFTSelectedCompanies] = useState([]);
  const [SWEIneternSelectedCompanies, setSWEInternSelectedCompanies] = useState(
    []
  );
  const [PMFTSelectedCompanies, setPMFTSelectedCompanies] = useState([]);
  const [PMInternSelectedCompanies, setPMInternSelectedCompanies] = useState(
    []
  );

  const baseSubscribeData = {
    category: "Software Engineer",
    country: [],
    frequency: "daily",
    company: [],
    yoe: [],
  };

  // function to get a new base data object
  const CreateNewSubscribeData = () => {
    // use spread operator to return a copy of the base data
    return { ...baseSubscribeData };
  };

  const handleSWEFulltimeChange = (event) => {
    setIsSWEFulltimeChecked(event.target.checked);
    if (event.target.checked) {
      mixpanel.track("SWEFT Alert Checked");
      const data = CreateNewSubscribeData();
      data.company = [...companies];
      data.yoe = [0];
      data.country = ["United States"];
      setFullTimeData(data);
    } else {
      setFullTimeData(null);
      mixpanel.track("SWEFT Alert Unchecked");
    }
  };

  const handleSWEInternChange = (event) => {
    setIsSWEInternChecked(event.target.checked);
    if (event.target.checked) {
      mixpanel.track("SWEIntern Alert Checked");
      const data = CreateNewSubscribeData();
      data.yoe = ["intern"];
      data.country = ["United States"];
      data.company = [...companies];
      setInternData(data);
    } else {
      setInternData(null);
      mixpanel.track("SWEIntern Alert Unchecked");
    }
  };

  const handlePMFulltimeChange = (event) => {
    setIsPMFulltimeChecked(event.target.checked);
    if (event.target.checked) {
      mixpanel.track("PMFT Alert Checked");
      const data = CreateNewSubscribeData();
      data.company = [...companies];
      data.yoe = [0];
      data.country = ["United States"];
      data.category = "Product Manager";
      setPMFullTimeData(data);
    } else {
      setPMFullTimeData(null);
      mixpanel.track("PMFT Alert Unchecked");
    }
  };

  const handlePMInternChange = (event) => {
    setIsPMInternChecked(event.target.checked);
    if (event.target.checked) {
      mixpanel.track("PMIntern Alert Checked");
      const data = CreateNewSubscribeData();
      data.yoe = ["intern"];
      data.country = ["United States"];
      data.company = [...companies];
      data.category = "Product Manager";
      setPMInternData(data);
    } else {
      setPMInternData(null);
      mixpanel.track("PMIntern Alert Unchecked");
    }
  };

  const handleFulltimeLocationChange = (event) => {
    mixpanel.track("SWEFT Alert Location");
    const location = event.target.value;
    if (location != "All Countries") {
      setFullTimeData((prevData) => ({
        ...prevData,
        country: location,
      }));
    } else {
      setFullTimeData((prevData) => ({
        ...prevData,
        country: ["United States", "Canada"],
      }));
    }
  };

  const handleInternLocationChange = (event) => {
    mixpanel.track("SWEIntern Alert Location");
    const location = event.target.value;
    if (location != "All Countries") {
      setInternData((prevData) => ({
        ...prevData,
        country: location,
      }));
    } else {
      setInternData((prevData) => ({
        ...prevData,
        country: ["United States", "Canada"],
      }));
    }
  };

  const handlePMFulltimeLocationChange = (event) => {
    mixpanel.track("PMFT Alert Location");
    const location = event.target.value;
    if (location != "All Countries") {
      setPMFullTimeData((prevData) => ({
        ...prevData,
        country: location,
      }));
    } else {
      setPMFullTimeData((prevData) => ({
        ...prevData,
        country: ["United States", "Canada"],
      }));
    }
  };

  const handlePMInternLocationChange = (event) => {
    mixpanel.track("PMIntern Alert Location");
    const location = event.target.value;
    if (location != "All Countries") {
      setPMInternData((prevData) => ({
        ...prevData,
        country: location,
      }));
    } else {
      setPMInternData((prevData) => ({
        ...prevData,
        country: ["United States", "Canada"],
      }));
    }
  };

  const checkSoftwareEngineerIntern = async (subscriptions, UserID) => {
    if (UserID) {
      // Loop over each entry in subscriptions
      for (const entry in subscriptions) {
        const subscription = subscriptions[entry];
        // console.log("check suscription", subscription);
        if (
          subscription.category === "Software Engineer" &&
          subscription.yoe.includes("intern")
        ) {
          // Return true or the subscription itself depending on your needs
          return subscription;
        }
      }
      //   console.log("No matching entries found");
      return false;
    } else {
      //   console.error("Login before subscribing");
    }
  };

  const checkSoftwareEngineerFulltime = async (subscriptions, UserID) => {
    // console.log("userid", userID);
    if (UserID) {
      // Loop over each entry in subscriptions
      for (const entry in subscriptions) {
        const subscription = subscriptions[entry];

        if (
          subscription.category === "Software Engineer" &&
          subscription.yoe.includes(0)
        ) {
          // Return true or the subscription itself depending on your needs
          return subscription;
        }
      }
      //   console.log("No matching entries found");
      return false;
    } else {
      //   console.error("Login before subscribing");
    }
  };

  const checkPMrFulltime = async (subscriptions, UserID) => {
    // console.log("userid", userID);
    if (UserID) {
      // Loop over each entry in subscriptions
      for (const entry in subscriptions) {
        const subscription = subscriptions[entry];

        if (
          subscription.category === "Product Manager" &&
          subscription.yoe.includes(0)
        ) {
          // Return true or the subscription itself depending on your needs
          return subscription;
        }
      }
      //   console.log("No matching entries found");
      return false;
    } else {
      //   console.error("Login before subscribing");
    }
  };

  const checkPMIntern = async (subscriptions, UserID) => {
    // console.log("userid", userID);
    if (UserID) {
      // Loop over each entry in subscriptions
      for (const entry in subscriptions) {
        const subscription = subscriptions[entry];

        if (
          subscription.category === "Product Manager" &&
          subscription.yoe.includes("intern")
        ) {
          // Return true or the subscription itself depending on your needs
          return subscription;
        }
      }
      //   console.log("No matching entries found");
      return false;
    } else {
      //   console.error("Login before subscribing");
    }
  };

  const handleExperienceChange = (event) => {
    mixpanel.track("SWEFT Alert Experience");
    // console.log(event.target.value); // prints the selected value
    const FulltimeYoe = Number(event.target.value);

    if (FulltimeYoe >= 1 && FullTimeData) {
      const newArray = Array.from(
        { length: FulltimeYoe + 1 },
        (_, index) => index
      ); // Generate an array [0, 1, ..., x]

      setFullTimeData((prevData) => ({
        ...prevData,
        yoe: newArray,
      }));
    }
  };

  const handlePMExperienceChange = (event) => {
    mixpanel.track("PMFT Alert Experience");
    // console.log(event.target.value); // prints the selected value
    const FulltimeYoe = Number(event.target.value);

    if (FulltimeYoe >= 1 && PMFulltimeData) {
      const newArray = Array.from(
        { length: FulltimeYoe + 1 },
        (_, index) => index
      ); // Generate an array [0, 1, ..., x]

      setPMFullTimeData((prevData) => ({
        ...prevData,
        yoe: newArray,
      }));
    }
  };

  const handleSubmit = async () => {
    mixpanel.track("Job Alert Submit");
    setIsSubmitLoading(true);
    await clearSubscribePreferences(userID);

    if (FullTimeData) {
      await UpdateSubscribePreferences(userID, FullTimeData);
    }
    if (InternData) {
      await UpdateSubscribePreferences(userID, InternData);
    }
    if (PMFulltimeData) {
      await UpdateSubscribePreferences(userID, PMFulltimeData);
    }
    if (PMInterneData) {
      await UpdateSubscribePreferences(userID, PMInterneData);
    }
    setSubmitted(true);
    setIsSubmitLoading(false);
  };

  const initializeData = async () => {
    const subscriptions = await getSubscribePreferences(userID);
    const internResult = await checkSoftwareEngineerIntern(
      subscriptions,
      userID
    );
    if (internResult) {
      // console.log("found intern swe data");
      setIsSWEInternChecked(true);
      const data = CreateNewSubscribeData();
      data.yoe = internResult.yoe;
      data.country = internResult.country;
      data.company = internResult.company;
      setInternData(data);
      setSWEInternSelectedCompanies(internResult.company);
    }
    const fulltimeResult = await checkSoftwareEngineerFulltime(
      subscriptions,
      userID
    );
    if (fulltimeResult) {
      // console.log("found ft swe data");
      setIsSWEFulltimeChecked(true);
      const data = CreateNewSubscribeData();
      data.yoe = fulltimeResult.yoe;
      data.country = fulltimeResult.country;
      data.company = fulltimeResult.company;
      setFullTimeData(data);
      setSWEFTSelectedCompanies(fulltimeResult.company);
      // console.log("remembered companies", fulltimeResult.company);
    }

    const PMfulltimeResult = await checkPMrFulltime(subscriptions, userID);
    if (PMfulltimeResult) {
      // console.log("found pm ft data");
      //   console.log("pm result", PMfulltimeResult.yoe);
      setIsPMFulltimeChecked(true);
      const data = CreateNewSubscribeData();
      data.category = PMfulltimeResult.category;
      data.yoe = PMfulltimeResult.yoe;
      data.country = PMfulltimeResult.country;
      data.company = PMfulltimeResult.company;
      setPMFullTimeData(data);
      setPMFTSelectedCompanies(PMfulltimeResult.company);
    }

    const PMInternResult = await checkPMIntern(subscriptions, userID);
    if (PMInternResult) {
      setIsPMInternChecked(true);
      const data = CreateNewSubscribeData();
      data.category = PMInternResult.category;
      data.yoe = PMInternResult.yoe;
      data.country = PMInternResult.country;
      data.company = PMInternResult.company;
      setPMInternData(data);
      setPMInternSelectedCompanies(PMInternResult.company);
    }
  };

  const getSelectedJobType = () => {
    let count = 0;
    const JobTypeArray = [
      FullTimeData,
      InternData,
      PMFulltimeData,
      PMInterneData,
    ];
    for (const index in JobTypeArray) {
      if (JobTypeArray[index]) {
        count = count + 1;
      }
    }
    return count;
  };

  const getYoePreferences = (JobData) => {
    if (JobData && JobData.yoe) {
      let max = Math.max(...JobData.yoe);
      return max.toString();
    } else return "0";
  };

  const handleSWEInternSelectedCompanyChange = (selectedCompanies) => {
    if (InternData) {
      setInternData((prevData) => ({
        ...prevData,
        company: selectedCompanies,
      }));
    }
  };

  const handlePMFTSelectedCompanyChange = (selectedCompanies) => {
    // setPMFTSelectedCompanies(selectedCompanies);
    if (PMFulltimeData) {
      setPMFullTimeData((prevData) => ({
        ...prevData,
        company: selectedCompanies,
      }));
    }
  };

  const handleSWEFTSelectedCompanyChange = (selectedCompanies) => {
    if (FullTimeData) {
      setFullTimeData((prevData) => ({
        ...prevData,
        company: selectedCompanies,
      }));
    }
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        // Fetch the specific document using the DocumentReference
        const data = await getDoc(companiesDocRef);
        // Check if document exists
        if (data.exists()) {
          const fetchedData = data.data().company_list;
          setCompanies(fetchedData);
          // Removed setSelectedCompanies(fetchedData); as it was not in the original function
        } else {
          console.error("No such document!");
        }
      } catch (err) {
        console.error(err);
      }
    };

    setSubmitted(false);
    fetchCompanyData();

    if (userID) {
      initializeData();
    }
  }, [userID]);

  const companiesCollectionRef = collection(db, "all_companies");

  return (
    <div>
      <div>
        <h2 className="mb-5 font-medium">
          Positions you'd like to receive email daily alerts for:
        </h2>
        <div className="flex items-center text-sm flex-wrap mb-8 text-gray-900  space-y-2 ">
          <input
            id="swe-fulltime-checkbox"
            type="checkbox"
            checked={isSWEFulltimeChecked}
            onChange={handleSWEFulltimeChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-gray-900 dark:text-gray-300"
          >
            👩🏻‍💻 Software Full-time, with less than
          </label>
          <select
            id="experience"
            name="experience"
            onChange={handleExperienceChange}
            value={getYoePreferences(FullTimeData)}
            className="ml-2 py-0.5 px-0 w-auto text-sm text-blue-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="0">0 year</option>
            <option value="1">1 year </option>
            <option value="2">2 years </option>
            <option value="3">3 years </option>
            <option value="4">4 years </option>
            <option value="5">5 years </option>
          </select>
          <p> of experience in </p>
          <select
            id="location"
            name="location"
            onChange={handleFulltimeLocationChange}
            defaultValue={"United States"}
            className="ml-2 py-0.5 px-0 w-auto text-sm text-blue-700  bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
            <option value="All Countries">US and Canada</option>
          </select>
          {/* <p className="ml-2">, at</p>{" "}
          <CompanySelection
            onSelectedCompanyChange={handleSWEFTSelectedCompanyChange}
            defaultCompanies={SWEFTSelectedCompanies}
          /> */}
        </div>
        <div className="flex text-sm items-center flex-wrap mb-8 text-gray-900 space-y-2 ">
          <input
            id="swe-intern-checkbox"
            type="checkbox"
            checked={isSWEInternChecked}
            onChange={handleSWEInternChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-gray-900 dark:text-gray-300"
          >
            🧑🏽‍💻 Software Internship
          </label>

          <p className="ml-2"> in </p>
          <select
            id="location"
            name="location"
            onChange={handleInternLocationChange}
            defaultValue={"United States"}
            className="ml-2 py-0.5 px-0 w-auto text-sm text-blue-700  bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
            <option value="All Countries">US and Canada</option>
          </select>
        </div>
        <div className="flex items-center text-sm flex-wrap mb-8 text-gray-900 space-y-2">
          <input
            id="swe-fulltime-checkbox"
            type="checkbox"
            checked={isPMFulltimeChecked}
            onChange={handlePMFulltimeChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-gray-900 dark:text-gray-300"
          >
            🙋🏼‍♂️ Product Manager Full-time, with less than
          </label>
          <select
            id="pm_experience"
            name="pm_experience"
            onChange={handlePMExperienceChange}
            value={getYoePreferences(PMFulltimeData)}
            className="ml-2 py-0.5 px-0 w-auto text-sm text-blue-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="0">0 year</option>
            <option value="1">1 year </option>
            <option value="2">2 years </option>
            <option value="3">3 years </option>
            <option value="4">4 years </option>
            <option value="5">5 years </option>
            <option value="6">6 years </option>
            <option value="7">7 years </option>
            <option value="8">8 years </option>
            <option value="9">9 years </option>
            <option value="10">10 years </option>
          </select>
          of experience in
          {/* <p className="pl-2"> in </p> */}
          <select
            id="pm_location"
            name="pm_location"
            onChange={handlePMFulltimeLocationChange}
            defaultValue={"United States"}
            className="ml-2 py-0.5 px-0 w-auto text-sm text-blue-700  bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
            <option value="All Countries">US and Canada</option>
          </select>
          {/* <p className="ml-2">, at</p>{" "}
          <CompanySelection
            onSelectedCompanyChange={handlePMFTSelectedCompanyChange}
            defaultCompanies={PMFTSelectedCompanies}
          /> */}
        </div>
        <div className="flex text-sm items-center flex-wrap mb-8 text-gray-900 space-y-2 ">
          <input
            id="pm-intern-checkbox"
            type="checkbox"
            checked={isPMInternChecked}
            onChange={handlePMInternChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-gray-900 dark:text-gray-300"
          >
            🙋 Product Manager Internship
          </label>

          <p className="ml-2"> in </p>
          <select
            id="location"
            name="location"
            onChange={handlePMInternLocationChange}
            defaultValue={"United States"}
            className="ml-2 py-0.5 px-0 w-auto text-sm text-blue-700  bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
            <option value="All Countries">US and Canada</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end pt-5">
        {userID && (
          <div className="flex items-center">
            {!submitted && (
              <span className="mr-2 text-sm text-gray-400">
                {" "}
                {getSelectedJobType()} roles selected
              </span>
            )}
            {isSubmitLoading && (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {submitted && !isSubmitLoading && (
              <span className="mr-2 text-sm text-green-500">
                Updated successfully!
              </span>
            )}
            <button
              className="inline-block text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-full text-sm px-3 py-1.5 mr-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        )}
        {!userID && (
          <div>
            <button
              className="flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-semibold rounded-full text-sm px-3 py-1 mr-2 mb-2 items-center"
              onClick={HandleLogin}
            >
              <img
                src={google_logo}
                className="w-4 h-4 mr-2"
                alt="Google Logo"
              />{" "}
              <span> Log in to continue</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobAlertWindow;

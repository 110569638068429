import backgroundImage from "../img/background.png";
import desktopBanner from "../img/companyBanner.png";
import mobileBanner from "../img/companyBanner_mobile.png";
import CompanyModal from "../CompanyModal";
import { HandleLogin, onAuthChange } from "../user/User";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../utils/authContext";
import mixpanel from "mixpanel-browser";

const PartnerBlog = () => {
  return (
    <div>
      <div className="mt-10">
        <section className="bg-center bg-no-repeat bg-blend-multiply bg-cover">
          <div className="px-4 mx-auto max-w-screen-xl text-center py-16 lg:py-16">
            <h2 className="mb-4 text-3xl font-bold tracking-tight leading-normal text-black md:text-3xl lg:text-4xl">
              🤝 Together, we can make things easier for{" "}
              <span className="underline underline-offset-3 decoration-8 decoration-blue-500 dark:decoration-blue-600">
                {" "}
                Students{" "}
              </span>
            </h2>
          </div>
        </section>
      </div>

      <div className="max-w-4xl mx-auto">
        <div className="py-10">
          <h5 className="text-xl text-left pb-4 font-bold dark:text-white">
            Partner With JobPulse
          </h5>
          <p className="mb-3 text-left text-gray-500 dark:text-gray-400">
            We welcome all forms of partnership. If you have innovative ideas,
            resources, or expertise, please let us know.
          </p>
          <div className="flex justify-start">
            <a
              href="mailto:hi@jobpulse.fyi"
              className="inline-flex items-center p-5 text-base font-medium text-gray-500 rounded-lg bg-gray-50 hover:text-gray-900 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
              </svg>
              <span className="pl-4" href="mailto:hi@jobpulse.fyi">
                {" "}
                hi@jobpulse.fyi
              </span>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="py-10">
          <h5 className="text-xl text-left pb-4 font-bold dark:text-white">
            Join the Team
          </h5>
          <p className="mb-3 text-left text-gray-500 dark:text-gray-400">
            We're on the lookout for passionate individuals like you who can
            turbocharge our efforts to simplify student job searches.
          </p>
          <div className="flex justify-start">
            <a
              href="https://forms.gle/QEJ6Zw9DFXMoZSp69"
              className="inline-flex items-center p-5 text-base font-medium text-gray-500 rounded-lg bg-gray-50 hover:text-gray-900 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM13.5 5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm-7 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM10 16c-3.047 0-5.5-2.735-5.5-5a.5.5 0 0 1 1 0 11.7 11.7 0 0 0 4.5.583c2.548 0 4.221-.371 4.5-.65a.515.515 0 0 1 .026-.106.457.457 0 0 1 .481-.372.531.531 0 0 1 .493.545c0 2.265-2.453 5-5.5 5Z" />
              </svg>
              <span className="pl-4">Tell Us About Yourself</span>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="py-10">
          <h5 className="text-xl text-left pb-4 font-bold dark:text-white">
            Buy Us a Coffee
          </h5>
          <p className="mb-3 text-left text-gray-500 dark:text-gray-400">
            Your treat's going straight to keeping our servers buzzing and
            happy! 🎉☕️
          </p>
          <div className="flex justify-start">
            <a
              href="https://www.buymeacoffee.com/gerogialin"
              className="inline-flex items-center p-5 text-base font-medium text-gray-500 rounded-lg bg-gray-50 hover:text-gray-900 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18"
              >
                <path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z" />
              </svg>
              <span className="pl-4">Buy Us a Coffee</span>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerBlog;

import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import mixpanel from "mixpanel-browser";
import Footer from "../layout/Footer";
import { useGlobalState } from "../state";

import ViewedJobList from "./ViewedJobList";

const UserPage = () => {
  useEffect(() => {
    mixpanel.track("User Page Viewed");
  }, []);
  return (
    <div>
      <Header />
      <ViewedJobList />
      <Footer />
    </div>
  );
};

export default UserPage;

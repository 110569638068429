import ProfilePlaceholder from "../../src/img/profile_placeholder.png";

const UserMilestone = () => {
  return (
    <div>
      <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg  dark:bg-gray-800 dark:border-gray-700">
        <div className="flex justify-end mx-4 px-4 pt-6"></div>
        <div className="flex flex-col items-center pb-10">
          <img
            className="w-10 h-10 mb-3 rounded-full"
            src={ProfilePlaceholder}
            alt="Bonnie image"
          />
          <h5 className="mb-1 text font-medium text-gray-900 dark:text-white">
            Tony Chang
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            @fraeht1385
          </span>
          <div className="flex mt-4 md:mt-6"></div>
          <div className="space-y-2 text-sm">
            <div className="flex justify-between items-center">
              <span className="text-gray-700">Application:</span>
              <span className="text-gray-700">1000</span>
              <button className="ml-2 px-1 border border-gray-300 rounded">
                +
              </button>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-700">OA:</span>
              <span className="text-gray-700">30</span>
              <button className="ml-2 px-1 border border-gray-300 rounded">
                +
              </button>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-700">Interview:</span>
              <span className="text-gray-700">10</span>
              <button className="ml-2 px-1 border border-gray-300 rounded">
                +
              </button>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-700">Offer:</span>
              <span className="text-gray-700">5</span>
              <button className="ml-2 px-1 border border-gray-300 rounded">
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMilestone;

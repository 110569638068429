import { useState, useContext } from "react";
import AuthContext from "../utils/authContext";
import mixpanel from "mixpanel-browser";
import PostModal from "./PostModal";

const PostHero = () => {
  const loggedIn = useContext(AuthContext);
  const [showPostModal, setShowPostModal] = useState(false);

  const handleToggleModalOpen = () => {
    setShowPostModal(true);
  };

  const handleToggleModalClose = () => {
    setShowPostModal(false);
  };

  return (
    <div>
      <div className="bg-blue-50 rounded-lg">
        <div className="p-8">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            A Place For{" "}
            <span className="underline underline-offset-3 decoration-8 decoration-blue-500 dark:decoration-blue-600">
              {" "}
              Students{" "}
            </span>{" "}
            to Talk About Job Search
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            A free website run by recent graduates who know how hard it is to
            get a job.
          </p>
          <button
            className="inline-flex justify-center items-center mt-4 py-2 px-5 text-base font-medium text-center text-white rounded-full bg-black hover:bg-gray-800 focus:ring-gray-600"
            onClick={handleToggleModalOpen}
          >
            Create post
          </button>
        </div>
      </div>

      <PostModal show={showPostModal} onClose={handleToggleModalClose} />
    </div>
  );
};

export default PostHero;

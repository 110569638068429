import React, { useEffect, useState } from "react";
import { getUserNameById } from "../user/User";

const CommentList = ({ comments }) => {
  const [usernames, setUsernames] = useState({});

  useEffect(() => {
    const fetchUsernames = async () => {
      const newUsernames = {};
      for (const comment of comments) {
        if (!usernames[comment.user_id]) {
          try {
            const username = await getUserNameById(comment.user_id);
            newUsernames[comment.user_id] = username;
          } catch (error) {
            console.error("Error fetching username:", error);
          }
        }
      }
      setUsernames((prev) => ({ ...prev, ...newUsernames }));
    };

    fetchUsernames();
  }, [comments]);

  return (
    <div>
      <div className="mt-4">
        {comments.map((comment, index) => (
          <div key={index} className="p-1 mb-1 rounded dark:bg-gray-700">
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {usernames[comment.user_id] || "Loading..."}
            </p>
            <p className="text text-gray-900 dark:text-gray-300">
              {comment.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentList;

import { useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  MarkAJobAsApplied,
  MarkAJobAsUnApplied,
  ViewThisJob,
  IsThisJobsApplied,
  IsThisJobsViewed,
} from "../user/User";
import { useGlobalState } from "../state";

import mixpanel from "mixpanel-browser";

import { Modal } from "flowbite-react";
import { HandleLogin } from "../user/User";
import { Tooltip } from "flowbite-react";

const ApplyButton = ({ jobId, onAppliedStatusChange }) => {
  const userID = useGlobalState("user_id");
  const loggedIn = useGlobalState("user_logedIn");
  const [showAlert, setShowAlert] = useState(false);

  const [isApplied, setIsApplied] = useState(false);
  const [isViewed, setIsViewed] = useState(false);

  const handleToggleModalOpen = () => {
    setShowAlert(!showAlert);
    mixpanel.track("Log In Alert");
  };

  const handleToggleModalClose = () => {
    setShowAlert(false);
  };

  const HandleMarkAsApplied = async () => {
    mixpanel.track("Mark as Applied");
    if (loggedIn[0]) {
      MarkAJobAsApplied(userID[0], jobId);
      setIsApplied(true);
      onAppliedStatusChange(true);
    } else {
      handleToggleModalOpen();
    }
  };

  const HandleMarkAsUnApplied = async () => {
    mixpanel.track("Unmark as Applied");
    if (loggedIn[0]) {
      MarkAJobAsUnApplied(userID, jobId);
      setIsApplied(false);
      onAppliedStatusChange(false);
    } else {
      console.log("not loggedin");
      setShowAlert(true);
    }
  };

  const HandleLoginHere = () => {
    HandleLogin();
    setShowAlert(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userID[0] && loggedIn[0]) {
        const applied = await IsThisJobsApplied(userID[0], jobId);
        const viewed = await IsThisJobsViewed(userID[0], jobId);
        setIsApplied(applied);
        setIsViewed(viewed);
      }
    };
    fetchData();
    // console.log("applubutton", isApplied, isViewed);
  }, [userID, loggedIn, jobId]);

  // console.log("Before rendering:", isApplied, isViewed);

  return (
    <div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal onClose={handleToggleModalClose} show={showAlert}>
          <Modal.Header>
            <div className="flex items-center justify-center">
              <span className="mr-4">
                🙌 Please log in before marking any jobs.
              </span>
              <button
                className="inline-block text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-full text-sm px-3 py-1 mr-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                onClick={HandleLoginHere}
              >
                Log In &rarr;
              </button>
            </div>
          </Modal.Header>
        </Modal>
      </div>

      <div className="flex items-center justify-center space-x-1">
        {/* {!isViewed && !isApplied && (
          <div>
            <button className="bg-black inline-flex items-center justify-center rounded-full px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-500">
              <p className="mr-1"> Apply</p>
              <svg
                className="w-2 h-2 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        )}
        {isApplied && (
          <button className="inline-flex justify-center gap-x-1.5 rounded-full px-3 py-1 text-xs font-semibold text-black border shadow-sm  mr-1">
            Applied
          </button>
        )}
        {isViewed && !isApplied && (
          <button
            // onClick={handleClick}
            className="text-gray-900 border border-gray-800 hover:bg-gray-100 hover:text-gray-800 rounded-full mr-1 font-semibold text-xs px-3 py-1 text-center "
          >
            Viewed
          </button>
        )} */}
        <Tooltip
          content={isApplied ? "Unmark as applied" : "Mark as applied"}
          style="light"
          placement="right"
          className="text-xs"
        >
          <div className="flex items-center">
            <input
              id="apply-checkbox"
              type="checkbox"
              checked={isApplied}
              className="w-4 h-4 text-gray-500 bg-gray-100 hover:bg-gray-300 rounded outline-none focus:ring-0 focus:ring-blue-500"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                if (e.target.checked) {
                  HandleMarkAsApplied();
                } else {
                  HandleMarkAsUnApplied();
                }
              }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ApplyButton;

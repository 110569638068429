import { collection, addDoc, Timestamp } from "firebase/firestore";
import db from "./firebase";
import {
  GetCurrentUserEamil,
  GetCurrentUserName,
  getUserEmailById,
  getUserNameById,
} from "../user/User";
import { createUserWithEmailAndPassword } from "firebase/auth";
import mixpanel from "mixpanel-browser";

export function BuildWelcomeMessage(userName) {
  return `
  <div>
  Hi ${userName}, <br /> <br />
     An and Georgia here, the makers of JobPulse. Thanks for signing up!  <br/><br/><b>Join our discord community for tech job seekers: </b><a href="https://discord.gg/R6rWBFhzF2">https://discord.gg/R6rWBFhzF2<a><br />
     <br />
     Best of luck with your job search!
     <br /> <br />
     Cheers,
     <br />
  <a href="https://www.linkedin.com/in/an-yu/">An Yu</a> and
     <a href="https://www.linkedin.com/in/zihui-georgia-lin-5b7600187/">
       Georgia Lin
     </a>
     <br />
   </div>
`;
}

export async function sendWelcomeEmail(userEmail, userName) {
  if (userEmail != null) {
    try {
      const mailCollection = collection(db, "mail");
      const now = Timestamp.fromDate(new Date());
      await addDoc(mailCollection, {
        to: userEmail,
        bcc: ["georgialin1999@gmail.com"],
        from: "Job Pulse <hi@jobpulse.fyi>",
        message: {
          subject: "Hello from An and Georgia 👋🏻",
          html: BuildWelcomeMessage(userName),
        },
      });

      mixpanel.track("Welcome Email Sent");
      // console.log("Email document added to Firestore successfully");
    } catch (error) {
      console.error("Error adding email document: ", error);
    }
  }
}

export function BuildReportMessage(job, user, issue) {
  return `
  <div>
  Hi Georgia, <br /> <br />
  User ${user} just reported this job ${issue}:   <br/>
   Title: ${job.title}<br/>
   ID: ${job.id}<br/>
   Company: ${job.company}<br/>

   </div>
`;
}

export async function sendAlertEmail(job, user, issue) {
  const mailCollection = collection(db, "mail");
  const now = Timestamp.fromDate(new Date());
  await addDoc(mailCollection, {
    to: "georgialin1999@gmail.com",
    from: "Job Pulse <hi@jobpulse.fyi>",
    message: {
      subject: "User Reported YOE error / Job invlid",
      html: BuildReportMessage(job, user, issue),
    },
  });

  mixpanel.track("Welcome Email Sent");
  // console.log("Email document added to Firestore successfully");
}

export function BuildShareJobThanksMessage(userName, job) {
  return `
  <div>
  Hi Georgia, <br /> <br />
  User ${userName} just reported this job:   <br/>
   Title: ${job.title}<br/>
   ID: ${job.id}<br/>
   Company: ${job.company}<br/>

   </div>
`;
}

export async function sendShareJobThanksEmail(job, userID) {
  if (userID) {
    const userEmail = await getUserEmailById(userID);
    const userName = await getUserNameById(userID);

    const mailCollection = collection(db, "mail");
    await addDoc(mailCollection, {
      to: userEmail,
      bcc: ["georgialin1999@gmail.com"],
      from: "Job Pulse <hi@jobpulse.fyi>",
      message: {
        subject: `🙌 ${userName}, your Job Listing is a Hit on JobPulse!`,
        html: `<div>
      Hi ${userName},
      <br><br>
      Your job listing for the <strong>${job.title}</strong> at <strong>${job.company}</strong> has received over 10 times the hits on <a href="https://jobpulse.fyi/">JobPulse</a>! Thanks again for contributing to the community.
      <br><br>
      Best regards,
      <br>
      The JobPulse Team
      <br><br>

    </div>`,
      },
    });
  }

  mixpanel.track("Thankyou Email Sent");
}
// console.log("Email document added to Firestore successfully");

import { useState, useEffect } from "react";
import { API_BASE_URL } from "../utils/config.js";
import PostItem from "./PostItem.js";
import { getUserNameById } from "../user/User.js";

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usernames, setUsernames] = useState({});

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/posts`);
        const data = await response.json();
        setPosts(data.posts);
        // console.log(data.posts[0]);

        // Fetch usernames for each post
        const usernamePromises = data.posts.map(async (post) => {
          const username = await getUserNameById(post.user_id);
          return { userId: post.user_id, username };
        });

        const usernameResults = await Promise.all(usernamePromises);
        const usernameMap = usernameResults.reduce((acc, user) => {
          acc[user.userId] = user.username;
          return acc;
        }, {});

        setUsernames(usernameMap);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {posts.map((post, index) => (
        <div key={index}>
          <PostItem
            company={post.company}
            jobTitle={post.job_title}
            postTitle={post.post_title}
            username={usernames[post.user_id] || "Unknown"}
            content={post.content}
            comments_count={post.comments_count}
            likes_count={post.view_count}
            postId={post.post_id}
            post_time={[post.post_time]}
          />
        </div>
      ))}
    </div>
  );
};

export default PostList;

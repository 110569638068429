import React from "react";
import { sendShareJobThanksEmail } from "./utils/Email";

const AdminTest = () => {
  const handleSendTestEmail = async () => {
    const uid = localStorage.getItem("uid"); // Assuming userID is stored in localStorage

    // Define the job object with necessary details
    const job = {
      id: "hpXr5r1YXh3ZgsVueOQ4",
      company: "Tesla",
      title: "test title",
    };

    // Call the sendShareJobThanksEmail function
    try {
      await sendShareJobThanksEmail(job, uid);
      console.log("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div>
      <button className="text-black" onClick={handleSendTestEmail}>
        Send Email
      </button>
    </div>
  );
};

export default AdminTest;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, setLogLevel } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// setLogLevel("debug");
const firebaseConfig = {
  apiKey: "AIzaSyCCxiNflE117TKohQUC_VKdH08pQhqi334",
  authDomain: "job-pulse.firebaseapp.com",
  projectId: "job-pulse",
  storageBucket: "job-pulse.appspot.com",
  messagingSenderId: "253551109979",
  appId: "1:253551109979:web:cc9b838f8a137814021c7e",
  measurementId: "G-YK7LV39RN9",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

export { db as firestore };

export default db;

export { app, functions };

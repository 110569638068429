import { API_BASE_URL } from "../utils/config.js";
import { useState, useEffect } from "react";

import { collection, query, where, getDocs } from "firebase/firestore";
import db from "../utils/firebase.js";

export function useFetchPosts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getPosts() {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/posts`);
        const data = await response.json();
        setPosts(data.posts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    }

    getPosts();
  }, []);

  return { posts, loading };
}
export async function createPost(newPost) {
  const idToken = localStorage.getItem("idToken");
  const uid = localStorage.getItem("uid");

  try {
    const response = await fetch(`${API_BASE_URL}/posts?uid=${uid}`, {
      method: "POST",
      headers: {
        Authorization: idToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPost),
    });
    const data = await response.json();
    console.log("Post created:", data);
    return data;
  } catch (error) {
    console.error("Error creating post:", error);
    throw error;
  }
}

export async function addComment(commentContent, postId) {
  const idToken = localStorage.getItem("idToken");
  const uid = localStorage.getItem("uid");

  try {
    const response = await fetch(
      `${API_BASE_URL}/posts/${postId}/comments?uid=${uid}`,
      {
        method: "POST",
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: commentContent }),
      }
    );
    const data = await response.json();
    console.log("Comment added:", data);
    return data; // Return the data for further processing if needed
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error; // Re-throw the error for handling in the calling code
  }
}

export async function fetchPostComments(postId) {
  const commentsRef = collection(db, "comments");
  const q = query(commentsRef, where("post_id", "==", postId));
  const querySnapshot = await getDocs(q);

  const comments = [];
  querySnapshot.forEach((doc) => {
    comments.push(doc.data());
  });

  const commentCount = comments.length;

  // console.log(`Fetched ${commentCount} comments for post ID: ${postId}`);
  // console.log("Comments:", comments);

  return { comments, commentCount };
}

import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
// import Footer from "./Footer";
import mixpanel from "mixpanel-browser";
import { API_BASE_URL } from "../utils/config";
// import "./PostExperimentPage.css"; // Import the CSS file

const PostExperimentPage = () => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState({
    category: "",
    company: "",
    job_title: "",
    post_title: "",
    content: "",
  });
  const [newComment, setNewComment] = useState({
    postId: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);
  const [postId, setPostId] = useState("");
  const [singlePost, setSinglePost] = useState(null);

  useEffect(() => {
    mixpanel.track("Post Experiment Page Viewed");
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/posts`);
      const data = await response.json();
      setPosts(data.posts);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSinglePost = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/posts/${postId}`);
      const data = await response.json();
      setSinglePost(data);
    } catch (error) {
      console.error("Error fetching post:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePost = async () => {
    const idToken = localStorage.getItem("idToken");
    const uid = localStorage.getItem("uid");
    try {
      const response = await fetch(`${API_BASE_URL}/posts?uid=${uid}`, {
        method: "POST",
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPost),
      });
      const data = await response.json();
      console.log("Post created:", data);
      fetchPosts();
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const handleDeletePost = async (postId) => {
    const idToken = localStorage.getItem("idToken");
    const uid = localStorage.getItem("uid");
    try {
      await fetch(`${API_BASE_URL}/posts/${postId}?uid=${uid}`, {
        method: "DELETE",
        headers: {
          Authorization: idToken,
        },
      });
      console.log("Post deleted:", postId);
      fetchPosts();
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleAddComment = async () => {
    const idToken = localStorage.getItem("idToken");
    const uid = localStorage.getItem("uid");
    try {
      const response = await fetch(`${API_BASE_URL}/posts/${newComment.postId}/comments?uid=${uid}`, {
        method: "POST",
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: newComment.content }),
      });
      const data = await response.json();
      console.log("Comment added:", data);
      fetchPosts(); // Optional: If you want to refresh the posts to see the new comment count
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (postId, commentId) => {
    const idToken = localStorage.getItem("idToken");
    const uid = localStorage.getItem("uid");
    try {
      await fetch(`${API_BASE_URL}/posts/${postId}/comments/${commentId}?uid=${uid}`, {
        method: "DELETE",
        headers: {
          Authorization: idToken,
        },
      });
      console.log("Comment deleted:", commentId);
      fetchPosts(); // Optional: If you want to refresh the posts to see the new comment count
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="container mx-80 p-50 pt-50"/>
      <div className="container mx-80 p-50 pt-50"/>
      <div className="container mx-80 p-50 pt-50"/>
      <div className="container mx-80 p-50 pt-50"/>
      <div className="container mx-auto p-4 pt-50">
        <h1 className="text-2xl font-bold mb-4">Post Experiment Page</h1>
        <div className="create-post mb-8 p-4 border border-gray-200 rounded">
          <h2 className="text-xl font-semibold mb-2">Create Post</h2>
          <input
            type="text"
            placeholder="Category"
            value={newPost.category}
            onChange={(e) => setNewPost({ ...newPost, category: e.target.value })}
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            placeholder="Company"
            value={newPost.company}
            onChange={(e) => setNewPost({ ...newPost, company: e.target.value })}
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            placeholder="Job Title"
            value={newPost.job_title}
            onChange={(e) => setNewPost({ ...newPost, job_title: e.target.value })}
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            placeholder="Post Title"
            value={newPost.post_title}
            onChange={(e) => setNewPost({ ...newPost, post_title: e.target.value })}
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <textarea
            placeholder="Content"
            value={newPost.content}
            onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          ></textarea>
          <button onClick={handleCreatePost} className="bg-blue-500 text-white p-2 rounded">
            Create Post
          </button>
        </div>

        <div className="posts">
          <h2 className="text-xl font-semibold mb-2">Posts</h2>
          {loading && <p>Loading...</p>}
          {!loading &&
            posts.map((post) => (
              <div key={post.post_id} className="post mb-4 p-4 border border-gray-200 rounded">
                <h3 className="text-lg font-bold">{post.post_title}</h3>
                <p>{post.content}</p>
                <pre>{JSON.stringify(post, null, 2)}</pre>
                <button
                  onClick={() => handleDeletePost(post.post_id)}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Delete Post
                </button>
                <div className="comments mt-4">
                  <h4 className="text-md font-semibold">Comments</h4>
                  {post.comments &&
                    post.comments.map((comment) => (
                      <div
                        key={comment.comment_id}
                        className="comment mb-2 p-2 border border-gray-300 rounded"
                      >
                        <p>{comment.content}</p>
                        <button
                          onClick={() => handleDeleteComment(post.post_id, comment.comment_id)}
                          className="bg-red-500 text-white p-2 rounded"
                        >
                          Delete Comment
                        </button>
                      </div>
                    ))}
                  <div className="add-comment mt-4">
                    <input
                      type="text"
                      placeholder="Comment Content"
                      value={newComment.content}
                      onChange={(e) =>
                        setNewComment({ ...newComment, content: e.target.value, postId: post.post_id })
                      }
                      className="w-full mb-2 p-2 border border-gray-300 rounded"
                    />
                    <button onClick={handleAddComment} className="bg-blue-500 text-white p-2 rounded">
                      Add Comment
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="single-post mb-8 p-4 border border-gray-200 rounded">
          <h2 className="text-xl font-semibold mb-2">Fetch Single Post</h2>
          <input
            type="text"
            placeholder="Enter Post ID"
            value={postId}
            onChange={(e) => setPostId(e.target.value)}
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <button onClick={fetchSinglePost} className="bg-green-500 text-white p-2 rounded">
            Fetch Post
          </button>
          {singlePost && (
            <div className="post mt-4 p-4 border border-gray-300 rounded">
              <h3 className="text-lg font-bold">{singlePost.post_title}</h3>
              <p>{singlePost.content}</p>
              <pre>{JSON.stringify(singlePost, null, 2)}</pre>
              <div className="comments mt-4">
                <h4 className="text-md font-semibold">Comments</h4>
                {singlePost.comments &&
                  singlePost.comments.map((comment) => (
                    <div
                      key={comment.comment_id}
                      className="comment mb-2 p-2 border border-gray-300 rounded"
                    >
                      <p>{comment.content}</p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default PostExperimentPage;

import React, { useEffect } from "react";

const ChatBot = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement("script");

    // Add script content
    script.innerHTML = `
      (function(d,t) {
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: 'XpMidSxpuusZfMXt7xdbbDK1',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
    `;

    // Append script to the document body
    document.body.appendChild(script);

    // Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything
};

export default ChatBot;

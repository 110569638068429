const Footer = () => {
  return (
    <div className="bg-gray-100 mt-16">
      <div className="px-4 mx-auto max-w-screen-xl text-center py-8 lg:py-24 ">
        {/* <div className="flex items-center justify-center min-h-screen"> */}{" "}
        {/* Add flex, items-center, justify-center, and min-h-screen classes */}
        {/* <footer className="bg-white rounded-lg m-4 dark:bg-gray-800 pt-10">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 my-10">
            © 2023{" "}
            <a href="https://flowbite.com/" className="hover:underline">
              JobPulse
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer> */}
        <footer className="bg-gray-100 dark:bg-gray-900">
          <div className="mx-auto w-full max-w-screen-xl">
            <div className="grid grid-cols-2 gap-8 px-4 py-4 lg:py-8 md:grid-cols-4 text-sm">
              <div>
                <h2 className="mb-6 font-semibold text-gray-900 dark:text-white">
                  Openings
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <a href="#" className=" hover:underline">
                      Software Full-Time
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="#" className="hover:underline">
                      Software Internship
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="#" className="hover:underline">
                      Product Full-Time
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="#" className="hover:underline">
                      Product Internship
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900  dark:text-white">
                  Follow Us
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <a
                      href="https://discord.com/invite/R6rWBFhzF2"
                      className="hover:underline"
                    >
                      Discord
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://www.linkedin.com/company/jobpulsefyi/"
                      className="hover:underline"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://www.instagram.com/jobpulse.fyi/"
                      className="hover:underline"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900  dark:text-white">
                  Support Us
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <a
                      href="https://www.buymeacoffee.com/gerogialin"
                      className="hover:underline"
                    >
                      Buy us a coffee
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://jobpulse.fyi/JoinUs"
                      className="hover:underline"
                    >
                      Partnership
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfX3aUcZmqClwX3kJmlQkyTqVEbvDYYUPW9PQPXhii3gs9-aA/viewform"
                      className="hover:underline"
                    >
                      Join the team
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900  dark:text-white">
                  JobPulse
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <a
                      href="https://medium.com/@jobpulse.fyi"
                      className="hover:underline"
                    >
                      Blog
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="mailto:hi@jobpulse.fyi"
                      className="hover:underline"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="px-4 py-6 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between">
              <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
                © 2023 <a href="https://jobpulse.fyi/">JobPulse</a>. All Rights
                Reserved.
              </span>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;

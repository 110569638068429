import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
  user_name: "Job Seeker",
  user_email: "",
  user_id: "",
  user_targetPosition: "Software Engineer",
  user_targetExperience: "entry level",
  user_logedIn: false,
  user_uid: "",
  user_photoURL: null,
  user_idtoken: null,
});

export { useGlobalState, setGlobalState };

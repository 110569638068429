import React, { useState, useEffect, useRef } from "react";
import { getDoc, doc, collection } from "firebase/firestore";
import db from "../utils/firebase";
import mixpanel from "mixpanel-browser";

function CompanySelectOne({ onSelectedCompanyChange }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [unselectedCompanies, setUnselectedCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const searchInput = useRef(null);
  const listRef = useRef(null);

  const [selectedCompany, setSelectedCompany] = useState("");

  const companiesCollectionRef = collection(db, "all_companies");
  const companiesDocRef = doc(db, "all_companies", "company_list");

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const data = await getDoc(companiesDocRef);

        if (data.exists()) {
          const fetchedData = data.data().company_list;
          setUnselectedCompanies(fetchedData);
        } else {
          console.error("No such document!");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchCompanyData();
  }, []);

  useEffect(() => {
    if (onSelectedCompanyChange) {
      onSelectedCompanyChange(selectedCompany);
    }
  }, [selectedCompany, onSelectedCompanyChange]);

  useEffect(() => {
    const results = unselectedCompanies.filter((company) =>
      company.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCompanies(results);
    setActiveOptionIndex(-1);
  }, [searchTerm, unselectedCompanies]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setActiveOptionIndex((prevIndex) => {
        const nextIndex = Math.min(prevIndex + 1, filteredCompanies.length - 1);
        if (listRef.current) {
          const listItems = listRef.current.querySelectorAll("li");
          if (listItems[nextIndex]) {
            listItems[nextIndex].scrollIntoView({ block: "nearest" });
          }
        }
        return nextIndex;
      });
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setActiveOptionIndex((prevIndex) => {
        const nextIndex = Math.max(prevIndex - 1, 0);
        if (listRef.current) {
          const listItems = listRef.current.querySelectorAll("li");
          if (listItems[nextIndex]) {
            listItems[nextIndex].scrollIntoView({ block: "nearest" });
          }
        }
        return nextIndex;
      });
    } else if (e.key === "Enter" && activeOptionIndex >= 0) {
      mixpanel.track("Company Search Add");
      e.preventDefault();
      handleOptionClick(filteredCompanies[activeOptionIndex]);
    } else if (e.key === "Delete" || e.key === "Backspace") {
      if (selectedCompany) {
        removeSelectedCompany();
      }
    }
  };

  const handleOptionClick = (company) => {
    mixpanel.track("Company Search Add");
    setSelectedCompany(company);
    setFilteredCompanies([]);
    setSearchTerm(""); // Clear the search term after selection
    setIsInputFocused(false); // Hide the list after selection
  };

  const removeSelectedCompany = () => {
    mixpanel.track("Company Search Remove");
    setSelectedCompany("");
    setSearchTerm("");
    searchInput.current.focus(); // Focus back on the search input
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="relative">
        <label htmlFor="simple-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for a company..."
            value={searchTerm || selectedCompany}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            ref={searchInput}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setTimeout(() => setIsInputFocused(false), 100)}
            readOnly={!!selectedCompany}
          />
        </div>

        {isInputFocused && (
          <ul
            ref={listRef}
            className="absolute w-full mt-2 bg-white border rounded shadow overflow-y-auto z-10"
            style={{ maxHeight: "200px" }}
          >
            {filteredCompanies.map((company, index) => (
              <li
                key={index}
                className={`p-2 cursor-pointer ${
                  index === activeOptionIndex ? "bg-blue-300" : ""
                }`}
                onMouseDown={() => handleOptionClick(company)}
                onMouseEnter={() => setActiveOptionIndex(index)}
              >
                {company}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default CompanySelectOne;

import React, { useState, useEffect, useRef } from "react";
import { useGlobalState } from "../state";

import ApplyButton from "./ApplyButton";
import mixpanel from "mixpanel-browser";
import {
  ViewThisJob,
  IgnoreThisJob,
  UndoIgnoreAJob,
  HandleLogin,
  IsThisJobsApplied,
  IsThisJobsViewed,
  IsThisJobIgnored,
  getUserEmailById,
} from "../user/User";

import { Tooltip, Button } from "flowbite-react";
import { updateDoc, getDoc, doc } from "firebase/firestore";
import db, { firestore } from "../utils/firebase";
import { Modal } from "flowbite-react";
import { sendAlertEmail } from "../utils/Email";
import { getLabelColors } from "./Job";
import ReactCardFlip from "react-card-flip";
import { handleCopyJobtoClipboard, addClickCount } from "./Job";
import { getUserNameById } from "../user/User";

function JobItem({
  job,
  isSponsorH1B,
  setIsSponsorH1B,
  firstOne,
  InternPage,
  PMPage,
  DSPage,
  todayLast,
}) {
  const userID = useGlobalState("user_id");
  const userName = useGlobalState("user_name");
  const loggedIn = useGlobalState("user_logedIn");
  const [showAlert, setShowAlert] = useState(false);
  const [showYoeModal, setShowYoeModal] = useState(false);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [showSponsorModal, setShowSponsorModal] = useState(false);
  const [uploadedUserName, setUploadedUserName] = useState("Job Pulse");

  const [isApplied, setIsApplied] = useState(false);
  const [isViewed, setIsViewed] = useState(false);
  const [hotJob, setisHotJob] = useState(false);

  const [isCopiedButton, setIsCopiedButton] = useState(false);

  const [isYOEInconsistencyReported, setYOEInconsistencyReported] = useState(
    job.reported_users?.length > 0
  );
  const [isInvalidReported, setIsInvalidReported] = useState(
    job.reportedUsers_invalid_jobs?.length > 0
  );

  const [isUnsponsorReported, setIsUnsponsorshipReported] = useState(
    job.reported_unsponsored?.length > 0
  );

  const [isThisUserReportedYoe, setIsThisUserReportedYoe] = useState(false);
  const [isThisUserReportedInvalid, setIsThisUserReportedInvalid] =
    useState(false);

  const [isThisUserReportedSponsorship, setIsThisUserReportedSponsorship] =
    useState(false);
  const [orignSponsorH1B, setOriginSponsorH1B] = useState(null);

  const [isIgnored, setIsIgnored] = useState(false);

  const [isReportMenuOpen, setIsReportMenuOpen] = useState(false);
  const dropdownRef = useRef();

  const [isFlipped, setIsFlipped] = useState(false);

  const shouldSetOpacity =
    isApplied || isInvalidReported || isIgnored ? " opacity-30" : "";

  const shouldSetLineThrough = isApplied || isIgnored ? " line-through" : "";

  const handleReportYOEInconsistency = async () => {
    if (!userID[0]) {
      handleToggleModalOpen();
      return;
    }

    setShowYoeModal(false);

    if (!isThisUserReportedYoe) {
      // console.log("handleReportYOEInconsistency, this user didn't report");
      sendAlertEmail(job, userName[0], "YOE wrong");
      mixpanel.track("Report Yoe Wrong");
    }

    let docRef;
    if (PMPage) {
      docRef = doc(db, "linkedin_pm_jobs", job.id);
    } else if (DSPage) {
      docRef = doc(db, "linkedin_ds_jobs", job.id);
    } else {
      docRef = doc(db, "linkedin_jobs", job.id);
    }
    const jobSnapshot = await getDoc(docRef);
    const jobData = jobSnapshot.data();
    let reportedUsers = jobData.reported_users || [];

    // extract the string user ID from the array
    const actualUserID = userID[0];

    if (reportedUsers.includes(actualUserID)) {
      //undo report
      reportedUsers = reportedUsers.filter((id) => id !== actualUserID);
      setIsThisUserReportedYoe(false);
      setYOEInconsistencyReported(false);
      // console.log("undo success");
    } else {
      //report yoe
      reportedUsers.push(actualUserID);
      setYOEInconsistencyReported(true);
      setIsThisUserReportedYoe(true);
    }
    // console.log(reportedUsers);
    await updateDoc(docRef, {
      reported_users: reportedUsers,
    });
  };

  const handleReportSponsorship = async () => {
    setShowSponsorModal(false);

    if (!isThisUserReportedSponsorship) {
      //didn't report before
      sendAlertEmail(job, userName[0], "Sponsorship Wrong");
      mixpanel.track("Report Sponsorship Wrong");
    }

    let docRef;
    if (PMPage) {
      docRef = doc(db, "linkedin_pm_jobs", job.id);
    } else if (DSPage) {
      docRef = doc(db, "linkedin_ds_jobs", job.id);
    } else {
      docRef = doc(db, "linkedin_jobs", job.id);
    }
    const jobSnapshot = await getDoc(docRef);
    const jobData = jobSnapshot.data() || {};
    let reportedUsersSponsorship = jobData.reported_unsponsored || [];
    // console.log("current sponsor report list: ", reportedUsersSponsorship);

    // extract the string user ID from the array
    const actualUserID = userID[0];

    if (reportedUsersSponsorship.includes(actualUserID)) {
      reportedUsersSponsorship = reportedUsersSponsorship.filter(
        (id) => id !== actualUserID
      );

      setIsThisUserReportedSponsorship(false);
      setIsSponsorH1B(orignSponsorH1B);
    } else {
      reportedUsersSponsorship.push(actualUserID);
    }

    await updateDoc(docRef, {
      reported_unsponsored: reportedUsersSponsorship,
    });

    if (reportedUsersSponsorship.includes(actualUserID)) {
      setIsUnsponsorshipReported(true);
      setIsThisUserReportedSponsorship(true);
      setIsSponsorH1B(false);
    } else if (reportedUsersSponsorship?.length > 0) {
      setIsUnsponsorshipReported(true);
      setIsSponsorH1B(false);
    } else {
      setIsUnsponsorshipReported(false);
      setIsSponsorH1B(orignSponsorH1B);
    }
  };

  const handleReportInvalid = async () => {
    setShowInvalidModal(false);

    if (!isThisUserReportedInvalid) {
      //didn't report before
      sendAlertEmail(job, userName[0], "Job invalid");
      mixpanel.track("Report Job Invalid");
    }

    let docRef;
    if (PMPage) {
      docRef = doc(db, "linkedin_pm_jobs", job.id);
    } else if (DSPage) {
      docRef = doc(db, "linkedin_ds_jobs", job.id);
    } else {
      docRef = doc(db, "linkedin_jobs", job.id);
    }
    const jobSnapshot = await getDoc(docRef);
    const jobData = jobSnapshot.data() || {};
    let reportedUsersInvalidJobs = jobData.reportedUsers_invalid_jobs || [];

    // extract the string user ID from the array
    const actualUserID = userID[0];

    if (reportedUsersInvalidJobs.includes(actualUserID)) {
      reportedUsersInvalidJobs = reportedUsersInvalidJobs.filter(
        (id) => id !== actualUserID
      );

      setIsThisUserReportedInvalid(false);
    } else {
      reportedUsersInvalidJobs.push(actualUserID);
    }

    await updateDoc(docRef, {
      reportedUsers_invalid_jobs: reportedUsersInvalidJobs,
    });

    if (reportedUsersInvalidJobs.includes(actualUserID)) {
      setIsInvalidReported(true);
      setIsThisUserReportedInvalid(true);
    } else if (reportedUsersInvalidJobs?.length > 0) {
      setIsInvalidReported(true);
    } else {
      setIsInvalidReported(false);
    }
  };

  const initReportStatus = async () => {
    let reportedUsers = job.reported_users || [];
    let reportedUsersInvalidJobs = job.reportedUsers_invalid_jobs || [];
    let reportedUsersSponsorship = job.reported_unsponsored || [];
    setOriginSponsorH1B(isSponsorH1B);

    // extract the string user ID from the array
    const actualUserID = userID[0];

    setYOEInconsistencyReported(reportedUsers.length > 0);
    setIsThisUserReportedYoe(reportedUsers.includes(actualUserID));

    setIsInvalidReported(reportedUsersInvalidJobs.length > 0);
    setIsThisUserReportedInvalid(
      reportedUsersInvalidJobs.includes(actualUserID)
    );

    setIsUnsponsorshipReported(reportedUsersSponsorship.length > 0);
    setIsSponsorH1B(!reportedUsersSponsorship.length > 0);
    setIsThisUserReportedSponsorship(
      reportedUsersSponsorship.includes(actualUserID)
    );
  };

  const handleAppliedStatusChange = (newAppliedStatus) => {
    setIsApplied(newAppliedStatus);
  };

  const handleToggleModalOpen = () => {
    setShowAlert(!showAlert);
    mixpanel.track("Log In Alert");
  };

  const handleToggleModalClose = () => {
    setShowAlert(false);
  };

  const handleYoeModalOpen = () => {
    if (!userID[0]) {
      handleToggleModalOpen();
      return;
    }

    if (isThisUserReportedYoe) {
      handleReportYOEInconsistency();
      return;
    }
    setShowYoeModal(!showYoeModal);
    mixpanel.track("Yoe Modal Alert");
    setIsReportMenuOpen(false);
  };

  const handleYoeModalClose = () => {
    setShowYoeModal(false);
    setIsReportMenuOpen(false);
  };

  const handleSponsorModalOpen = () => {
    if (!userID[0]) {
      handleToggleModalOpen();
      return;
    }

    if (isThisUserReportedSponsorship) {
      handleReportSponsorship();
      return;
    }
    setShowSponsorModal(!showSponsorModal);
    mixpanel.track("Sponsor Modal Alert");
    setIsUnsponsorshipReported(false);
  };

  const handleSponsorModalClose = () => {
    setShowSponsorModal(false);
    setIsUnsponsorshipReported(false);
  };

  const handleInvalidModalOpen = () => {
    if (!userID[0]) {
      handleToggleModalOpen();
      return;
    }
    if (isThisUserReportedInvalid) {
      handleReportInvalid();
      return;
    }
    setShowInvalidModal(!showInvalidModal);
    mixpanel.track("Invalid Alert");
    setIsReportMenuOpen(false);
  };

  const handleInvalidModalClose = () => {
    setShowInvalidModal(false);
    setIsReportMenuOpen(false);
  };

  const HandleLoginHere = () => {
    HandleLogin();
    setShowAlert(false);
  };

  const handleIgnoreJob = () => {
    if (!userID[0]) {
      setShowAlert(!showAlert);
    }
    if (!isIgnored) {
      IgnoreThisJob(userID[0], job.id);
    } else {
      UndoIgnoreAJob(userID[0], job.id);
    }
    setIsIgnored(!isIgnored);
  };

  const handleCopyJob = async () => {
    let copied = await handleCopyJobtoClipboard(job);
    if (copied) {
      setIsCopiedButton(true); // Show the button if the copy is successful
      mixpanel.track("Copied Job Info");
      setTimeout(() => {
        setIsCopiedButton(false); // Hide the button after a delay (e.g., 5 seconds)
      }, 5000);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (userID[0] && loggedIn[0]) {
        const applied = await IsThisJobsApplied(userID[0], job.id);
        const viewed = await IsThisJobsViewed(userID[0], job.id);
        const ignored = await IsThisJobIgnored(userID[0], job.id);

        setIsApplied(applied);
        setIsViewed(viewed);
        setIsIgnored(ignored);
      }
      if (job.upload_user) {
        const upload_username = await getUserNameById(job.upload_user);
        const upload_user_initial = getInitials(upload_username);

        setUploadedUserName(upload_user_initial);
      }
      await initReportStatus();
    };
    fetchData();

    // Determine the threshold based on the page type
    let hotJobThreshold;
    if (PMPage || DSPage) {
      hotJobThreshold = 30;
    } else if (InternPage) {
      hotJobThreshold = 40;
    } else {
      hotJobThreshold = 100;
    }

    if (job && job.click_count >= hotJobThreshold) {
      setisHotJob(true);
    }
  }, [userID[0], loggedIn[0], job]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsReportMenuOpen(false);
      }
    };

    if (isReportMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isReportMenuOpen]);

  const handleApplyNow = (e, jobLink, jobID) => {
    mixpanel.track("Click Apply Now", {
      title: job.title,
      company: job.company,
      link: job.apply_link ? job.apply_link : job.link,
      // ... more properties
    });

    addClickCount(job.id, PMPage, DSPage, job.upload_user);
    setIsViewed(true);
    ViewThisJob(userID[0], jobID);
  };

  // function getH1BToolTip(isSponsorH1B, company_name) {
  //   if (isSponsorH1B === true) {
  //     return `${company_name} has filed H1B before.`;
  //   } else if (isSponsorH1B === false) {
  //     return `${company_name} has never filed H1B before.`;
  //   } else if (isSponsorH1B === "?") {
  //     return `Rumors suggest that ${company_name} is not sponsoring some positions.`;
  //   } else {
  //     return `H1B sponsorship status for ${company_name} is unknown.`;
  //   }
  // }

  function getInitials(name) {
    if (!name) return "U.K.";

    // Split the name by spaces to get each word
    const words = name.trim().split(/\s+/);

    // Get the first letter of each word, convert to uppercase, and append a period
    const initials = words
      .map((word) => word.charAt(0).toUpperCase() + ".")
      .join(" ");

    return initials;
  }

  function getStatusBadge(isSponsorH1B) {
    let badgeText = "";
    let bgColor = "";
    let textColor = "";

    if (isSponsorH1B === true) {
      badgeText = "✓";
      bgColor = isInvalidReported ? "bg-gray-100" : "bg-green-100";
      textColor = isInvalidReported ? "text-gray-700" : "text-green-800";
    } else if (isSponsorH1B === false) {
      badgeText = "No";
      bgColor = isInvalidReported ? "bg-gray-100" : "bg-red-200";
      textColor = isInvalidReported ? "text-gray-700" : "text-red-800";
    } else if (isSponsorH1B === "?") {
      badgeText = "?";
      bgColor = isInvalidReported ? "bg-gray-100" : "bg-purple-200";
      textColor = isInvalidReported ? "text-gray-700" : "text-purple-800";
    } else {
      badgeText = "...";
      bgColor = isInvalidReported ? "bg-gray-100" : "bg-gray-200";
      textColor = isInvalidReported ? "text-gray-700" : "text-gray-700";
    }

    return (
      <span
        className={`text-left
          ${shouldSetLineThrough}
         text-xs font-medium mr-2 px-1.5 py-0.5 rounded ${bgColor} ${textColor}`}
        style={shouldSetOpacity ? { opacity: 0.3 } : {}}
      >
        {badgeText}
      </span>
    );
  }

  return (
    <div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal onClose={handleToggleModalClose} show={showAlert}>
          <Modal.Header>
            <div className="flex items-center justify-center">
              <span className="mr-4">🙌 Please log in first.</span>
              <button
                className="inline-block text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-full text-sm px-3 py-1 mr-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                onClick={HandleLoginHere}
              >
                Log In &rarr;
              </button>
            </div>
          </Modal.Header>
        </Modal>
        <Modal onClose={handleInvalidModalClose} show={showInvalidModal}>
          <Modal.Header>
            <div className="flex items-center justify-center">
              <span className="mr-4 font-medium text-gray-900 text-md">
                🧐 Are you sure this job is closed or invalid?
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="font-medium text-gray-900">{job.title}</p>
              <p className="font-medium text-gray-500"> @{job.company}</p>

              <p className="text-xs text-blue-700 py-4">
                *Kindly be aware that flagging the job as closed shares
                information with other users too.
              </p>
              <div className="flex justify-end pt-4">
                <button
                  className="text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-full text-sm px-3 py-1 mr-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  onClick={handleReportInvalid}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal onClose={handleYoeModalClose} show={showYoeModal}>
          <Modal.Header>
            <div className="flex items-center justify-center">
              <span className="mr-4 font-medium text-gray-900 text-md">
                🧐 Are you sure the Year Of Experience is wrong?
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="font-medium text-gray-900">{job.title}</p>
              <p className="font-medium text-gray-500"> @{job.company}</p>

              <p className="font-medium text-sm py-4">
                {" "}
                Year of Experience:{" "}
                <span className="text-red-500 font-semibold">{job.yoe}</span>
              </p>
              <p className="text-xs text-blue-700 py-4">
                *Kindly be aware that flagging the YOE as wrong shares
                information with other users too.
              </p>
              <div className="flex justify-end pt-4">
                <button
                  className="text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-full text-sm px-3 py-1 mr-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  onClick={handleReportYOEInconsistency}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal onClose={handleSponsorModalClose} show={showSponsorModal}>
          <Modal.Header>
            <div className="flex items-center justify-center">
              <span className="mr-4 font-medium text-gray-900 text-md">
                🧐 Are you sure this job doesn't sponsor?
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="font-medium text-gray-900">{job.title}</p>
              <p className="font-medium text-gray-500"> @{job.company}</p>

              <p className="font-medium text-sm py-4">
                {" "}
                Sponsorship:{" "}
                <span className="text-xs font-medium mr-2 px-1.5 py-0.5 rounded bg-red-200 text-red-800 ">
                  No Sponsorship
                </span>
              </p>
              <p className="text-xs text-blue-700 py-4">
                *Kindly be aware that you will share the sponsorship information
                with other users too.
              </p>
              <div className="flex justify-end pt-4">
                <button
                  className="text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-full text-sm px-3 py-1 mr-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  onClick={handleReportSponsorship}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {isCopiedButton && (
          <div className="fixed-button">
            {" "}
            <button
              type="button"
              className="text-white bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 "
            >
              Job Copied to Clipboard
            </button>
          </div>
        )}
      </div>

      <div key={job.id} className="bg-white rounded-md">
        {/* mobile screen */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={job.apply_link ? job.apply_link : job.link}
          onClick={(e) => {
            e.stopPropagation();
            handleApplyNow(
              e,
              job.apply_link ? job.apply_link : job.link,
              job.id
            );
          }}
          className="block relative"
        >
          <div className="flex flex-col items-center  sm:block md:flex lg:hidden text-sm text-left my-2">
            <div className="w-full p-3 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                  {job.company}
                </h2>
                <div className="text-right">
                  <span
                    className={`px-3 py-1 rounded-full text-xs ${
                      getLabelColors(job.days).bgColor
                    } ${getLabelColors(job.days).textColor}`}
                  >
                    {job.postTime}
                  </span>
                </div>
              </div>
              <p
                className={`mb-3 font-normal text-gray-700 dark:text-gray-400`}
              >
                {job.title}
              </p>

              <button
                className="bg-black text-white px-3 py-1 rounded-full inline-block text-xs"
                onClick={(e) => {
                  e.stopPropagation();
                  handleApplyNow(
                    e,
                    job.apply_link ? job.apply_link : job.link,
                    job.id
                  );
                }}
              >
                Apply now
              </button>
            </div>
          </div>
        </a>

        {/* larger screen */}
        {firstOne && (
          <div className="hidden lg:block my-2 rounded-md ">
            <div className="flex w-full text-xs text-left bg-gray-50 font-bold text-gray-700 items-start justify-start px-4 py-2">
              <div style={{ width: "2%" }}></div>
              <div style={{ width: "15%" }}>
                <h2>🏙️ Company</h2>
              </div>

              <div style={{ width: "50%" }}>
                <p>💼 Job</p>
              </div>
              {/* <div style={{ width: "3%" }}></div> */}
              <div style={{ width: "8%" }}>💵 Sponsor H1B?</div>

              <div style={{ width: "5%" }}>
                {!InternPage && <span>🗓️ Yoe</span>}
              </div>

              <div style={{ width: "10%" }}>🕓 Posted</div>
              <div style={{ width: "10%" }}>⏫ Applied?</div>
            </div>
          </div>
        )}

        <a
          href={job.apply_link ? job.apply_link : job.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleApplyNow(
              e,
              job.apply_link ? job.apply_link : job.link,
              job.id
            );
          }}
        >
          <div
            className={`hidden lg:block p-4 my-2 rounded-md ${
              !isInvalidReported ? "shadow" : "shadow"
            }`}
          >
            <div className="sm:flex items-center items-start text-sm justify-start ">
              <div className="flex-none no-underline" style={{ width: "2%" }}>
                {" "}
                {isApplied ? (
                  <Tooltip
                    content="You have applied for this job."
                    style="light"
                    placement="top"
                    className="text-xs"
                  >
                    <span className="px-1.5 py-0.5 rounded text-xs text-gray-700">
                      ✔️
                    </span>
                  </Tooltip>
                ) : isViewed ? (
                  <Tooltip
                    content="You have viewed this job."
                    style="light"
                    placement="top"
                    className="text-xs"
                  >
                    <span className="px-1.5 py-0.5 rounded text-xs text-gray-700">
                      👀
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    content="This is a new job for you."
                    style="light"
                    placement="top"
                    className="text-xs"
                  >
                    <span className="px-1.5 py-0.5 rounded text-xs text-gray-700">
                      🌟
                    </span>
                  </Tooltip>
                )}
              </div>
              <div style={{ width: "15%" }}>
                <span className={`text-left ${shouldSetLineThrough}`}>
                  <h2
                    className={`ml-1 font-semibold text-base text-black`}
                    style={shouldSetOpacity ? { opacity: 0.3 } : {}}
                  >
                    <span style={{ paddingLeft: "8px" }}>{job.company}</span>
                  </h2>
                </span>
              </div>

              <div
                className={`flex text-left flex-grow truncate w-1/2 ${shouldSetLineThrough}`}
                style={shouldSetOpacity ? { opacity: 0.3 } : {}}
              >
                <p
                  className={`underline ${
                    !isViewed
                      ? isApplied || isInvalidReported
                        ? "text-gray-500"
                        : "text-blue-600"
                      : isApplied || isInvalidReported
                      ? "text-gray-500"
                      : "text-purple-800"
                  }`}
                >
                  {job.title}
                </p>
                {hotJob && <span className="ml-1">🔥</span>}{" "}
              </div>

              <div style={{ width: "8%" }}>
                {/* <Tooltip
                  content={getH1BToolTip(isSponsorH1B, job.company)}
                  style="light"
                  placement="top"
                  className="text-xs"
                > */}
                {getStatusBadge(isSponsorH1B)}
                {/* </Tooltip> */}
              </div>

              {job.yoe === "intern" ? null : isYOEInconsistencyReported || // Don't show anything if yoe is "intern"
                ![0, 1, 2, 3, 4, 5].includes(Number(job.yoe)) ? (
                <div
                  className={`flex justify-center ${shouldSetLineThrough}`}
                  style={{ width: "5%" }}
                >
                  <Tooltip
                    content="Users say this YOE label might be wrong."
                    style="light"
                    placement="top"
                    className="text-xs"
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className={`text-xs font-medium mr-2 px-1.5 py-0.5 rounded ${
                        isInvalidReported
                          ? "bg-gray-200 text-gray-800"
                          : "bg-purple-200 text-purple-800"
                      }`}
                      style={shouldSetOpacity ? { opacity: 0.3 } : {}}
                    >
                      ?
                    </span>
                  </Tooltip>
                </div>
              ) : !isNaN(job.yoe) &&
                Number(job.yoe) >= 0 &&
                Number(job.yoe) < 15 &&
                Number.isInteger(parseFloat(job.yoe)) ? (
                <div
                  className={`flex-none ${shouldSetLineThrough}`}
                  style={{ width: "5%" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={`text-xs font-medium mr-2 px-1.5 py-0.5 rounded ${
                      isInvalidReported
                        ? "bg-gray-200 text-gray-800"
                        : "bg-yellow-200 text-yellow-800"
                    }`}
                    style={shouldSetOpacity ? { opacity: 0.3 } : {}}
                  >
                    {job.yoe}
                  </span>
                </div>
              ) : null}

              <div
                className={`flex justify-center items-center ${shouldSetLineThrough}`}
                style={{ width: "10%" }}
                onMouseEnter={() => setIsFlipped(true)}
                onMouseLeave={() => setIsFlipped(false)}
              >
                <ReactCardFlip isFlipped={isFlipped}>
                  <div key="front">
                    {isInvalidReported ? (
                      <span className="px-1.5 py-0.5 rounded text-xs bg-gray-200 text-gray-700">
                        Closed
                      </span>
                    ) : (
                      <span
                        className={`px-1.5 py-0.5 rounded text-xs ${
                          getLabelColors(job.days).bgColor
                        } ${getLabelColors(job.days).textColor}`}
                        style={shouldSetOpacity ? { opacity: 0.3 } : {}}
                      >
                        {job.postTime}
                      </span>
                    )}
                  </div>

                  <div key="back">
                    <div
                      className={`flex justify-center items-center px-2.5 py-0.5 rounded text-xs  ${
                        getLabelColors(job.days).bgColor
                      } ${getLabelColors(job.days).textColor}`}
                    >
                      {job.formattedDate}
                    </div>
                  </div>
                </ReactCardFlip>
              </div>

              <div className="flex-none no-underline" style={{ width: "10%" }}>
                <ApplyButton
                  jobId={job.id}
                  onAppliedStatusChange={handleAppliedStatusChange}
                />
              </div>

              <div style={{ width: "2%" }}>
                <div
                  className="relative inline-block text-left "
                  ref={dropdownRef}
                >
                  <button
                    className="ml-3 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-white rounded p-1"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsReportMenuOpen(!isReportMenuOpen);
                    }}
                  >
                    <svg
                      className="w-3 h-3 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 4 15"
                    >
                      <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                    </svg>
                  </button>

                  {isReportMenuOpen && (
                    <div
                      id="dropdown"
                      className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                    >
                      <ul
                        className="py-2 text-xs text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleCopyJob();
                              // handleReportYOEInconsistency();
                            }}
                            className="flex block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Share Job Info
                            <svg
                              className="ml-2 w-3 h-3 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 18 18"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                              />
                            </svg>
                          </div>
                        </li>
                        <li>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleIgnoreJob();
                              // handleReportYOEInconsistency();
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            {!isIgnored && "Ignore This Job"}
                            {isIgnored && "Undo Ignore"}
                          </div>
                        </li>
                        <li>
                          <hr className="border-t border-gray-200 dark:border-gray-700 my-1" />
                        </li>
                        <li>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              // handleReportInvalid();
                              handleInvalidModalOpen();
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            {isThisUserReportedInvalid && "Mark Job as Open"}
                            {!isThisUserReportedInvalid && "Report Job Closed"}
                          </div>
                        </li>
                        <li>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleYoeModalOpen();
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            {isThisUserReportedYoe && "Undo YOE Report"}
                            {!isThisUserReportedYoe && "Report YOE Incorrect"}
                          </div>
                        </li>
                        <li>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleSponsorModalOpen();
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            {isThisUserReportedSponsorship &&
                              "Undo Report Sponsor"}
                            {!isThisUserReportedSponsorship &&
                              "Report Sponsorship"}
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {job.isRecommended && (
              <div className="sm:flex items-center items-start text-sm justify-start mt-2">
                <span className="flex bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 mr-1 rounded dark:bg-gray-700 dark:text-yellow-400 ">
                  <svg
                    className="w-3 h-3 text-yellow-800 dark:text-white mr-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 18"
                  >
                    <path d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z" />
                  </svg>
                  Verified
                </span>
              </div>
            )}
            {job.upload_user && (
              <div className="sm:flex items-center items-start text-xs justify-start mt-2 mx-1 text-gray-500">
                User
                <span className="font-medium text-gray-700 dark:text-gray-300 mx-1">
                  {uploadedUserName}
                </span>
                shared this job
              </div>
            )}
          </div>
        </a>
        <div>
          {todayLast && (
            <div>
              <div className="inline-flex items-center justify-center w-full">
                <hr className="w-full h-1 my-8 bg-gray-200 border-0  rounded dark:bg-gray-700" />
                <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
                  <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-3 py-2 my-5 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    ⬆️ Above are new jobs in last 24 hours 🔥
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobItem;

import React, { useState, useEffect, useRef } from "react";
import { useGlobalState } from "../state";
import { IsThisJobsApplied, IsThisJobsViewed } from "../user/User";
import ApplyButton from "./ApplyButton";
import mixpanel from "mixpanel-browser";
import { ViewThisJob } from "../user/User";
// import { useViewedJob } from "./ViewedJobsContext";
import { Tooltip, Button } from "flowbite-react";

function JobItemUserPage({ job, isSponsorH1B, firstOne, InternPage, PMPage }) {
  const userID = useGlobalState("user_id");
  const userName = useGlobalState("user_name");
  const loggedIn = useGlobalState("user_logedIn");
  const [showAlert, setShowAlert] = useState(false);

  const [isApplied, setIsApplied] = useState(false);
  const [isViewed, setIsViewed] = useState(false);
  // const [, setLastViewedJob] = useViewedJob();
  const [isYOEInconsistencyReported, setYOEInconsistencyReported] = useState(
    job.reported_users?.length > 0
  );
  const [isInvalidReported, setIsInvalidReported] = useState(
    job.reportedUsers_invalid_jobs?.length > 0
  );

  const [isThisUserReportedYoe, setIsThisUserReportedYoe] = useState(false);
  const [isThisUserReportedInvalid, setIsThisUserReportedInvalid] =
    useState(false);

  // const [isYoeReportHovered, setisYoeReportIsHovered] = useState(false);
  // const [isInvalidReportHovered, setIsInvalidReportHovered] = useState(false);

  const dropdownRef = useRef();

  const initReportStatus = async () => {
    // let docRef;

    // if (PMPage) {
    //   docRef = doc(db, "linkedin_pm_jobs", job.id);
    // } else {
    //   docRef = doc(db, "linkedin_jobs", job.id);
    // }

    // const jobSnapshot = await getDoc(docRef);

    // if (!jobSnapshot.exists()) {
    //   console.error("Job not found:", job.id);
    //   return; // Exit if job document doesn't exist
    // }

    // const jobData = jobSnapshot.data();

    // if (!jobData) {
    //   console.error("Failed to fetch jobData");
    //   return;
    // }

    // Default to empty arrays if these properties are not set
    const reportedUsers = job?.reported_users || [];
    const reportedUsersInvalidJobs = job?.reportedUsers_invalid_jobs || [];

    if (!userID || !Array.isArray(userID) || userID.length === 0) {
      console.error("userID is either not set, not an array, or empty");
      return;
    }

    const actualUserID = userID[0];

    if (!Array.isArray(reportedUsers)) {
      console.error("reportedUsers is not an array:", reportedUsers);
      return;
    }

    if (!Array.isArray(reportedUsersInvalidJobs)) {
      console.error(
        "reportedUsersInvalidJobs is not an array:",
        reportedUsersInvalidJobs
      );
      return;
    }

    setYOEInconsistencyReported(reportedUsers.includes(actualUserID));
    if (reportedUsers.includes(actualUserID)) {
      setIsThisUserReportedYoe(true);
    }

    setIsInvalidReported(reportedUsersInvalidJobs.includes(actualUserID));
    if (reportedUsersInvalidJobs.includes(actualUserID)) {
      setIsThisUserReportedInvalid(true);
    }
  };

  const handleAppliedStatusChange = (newAppliedStatus) => {
    setIsApplied(newAppliedStatus);
  };

  const getLabelColors = (days) => {
    if (days <= 1) {
      return { bgColor: "bg-green-200", textColor: "text-green-800" };
    } else if (days <= 3) {
      return { bgColor: "bg-blue-200", textColor: "text-blue-800" };
    } else if (days <= 13)
      return { bgColor: "bg-blue-200", textColor: "text-blue-800" };

    return { bgColor: "bg-gray-200", textColor: "text-gray-800" };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userID[0] && loggedIn[0]) {
        const applied = await IsThisJobsApplied(userID[0], job.id);
        const viewed = await IsThisJobsViewed(userID[0], job.id);
        await initReportStatus();
        setIsApplied(applied);
        setIsViewed(viewed);
      }
    };
    fetchData();
  }, [userID, loggedIn, job]);

  const handleApplyNow = (e, jobLink, jobID) => {
    mixpanel.track("Click Apply Now");
    ViewThisJob(userID[0], jobID);
  };

  function getH1BToolTip(isSponsorH1B, company_name) {
    if (isSponsorH1B === true) {
      return `${company_name} has filed H1B before.`;
    } else if (isSponsorH1B === false) {
      return `${company_name} has never filed H1B before.`;
    } else if (isSponsorH1B === "?") {
      return `Rumors suggest that ${company_name} is not sponsoring some positions.`;
    } else {
      return `H1B sponsorship status for ${company_name} is unknown.`;
    }
  }

  function getStatusBadge(isSponsorH1B) {
    let badgeText = "";
    let bgColor = "";
    let textColor = "";

    if (isSponsorH1B === true) {
      badgeText = "✓";
      bgColor = "bg-green-100";
      textColor = "text-green-800";
    } else if (isSponsorH1B === false) {
      badgeText = "No";
      bgColor = "bg-red-200";
      textColor = "text-red-800";
    } else if (isSponsorH1B === "?") {
      badgeText = "?";
      bgColor = "bg-purple-200";
      textColor = "text-purple-800";
    } else {
      badgeText = "...";
      bgColor = "bg-gray-200";
      textColor = "text-gray-700";
    }

    return (
      <span
        className={`text-left text-xs font-medium mr-2 px-1.5 py-0.5 rounded ${bgColor} ${textColor}`}
      >
        {badgeText}
      </span>
    );
  }

  return (
    <div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      ></div>

      <div key={job.id} className="bg-white rounded-md">
        {/* mobile screen */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={job.apply_link ? job.apply_link : job.link}
          onClick={(e) => {
            e.stopPropagation();
            handleApplyNow(
              e,
              job.apply_link ? job.apply_link : job.link,
              job.id
            );
          }}
          className="block relative"
        >
          <div className="flex flex-col items-center  sm:block md:flex lg:hidden text-sm text-left my-2">
            <div className="w-full p-3 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                  {job.company}
                </h2>
                <div className="text-right">
                  <span
                    className={`px-3 py-1 rounded-full text-xs ${
                      getLabelColors(job.days).bgColor
                    } ${getLabelColors(job.days).textColor}`}
                  >
                    {job.postTime}
                  </span>
                </div>
              </div>
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                {job.title}
              </p>

              <button
                className="bg-black text-white px-3 py-1 rounded-full inline-block text-xs"
                onClick={(e) => {
                  e.stopPropagation();
                  handleApplyNow(
                    e,
                    job.apply_link ? job.apply_link : job.link,
                    job.id
                  );
                }}
              >
                Apply now
              </button>
            </div>
          </div>
        </a>

        {/* larger screen */}
        {firstOne && (
          <div className="hidden lg:block my-2 rounded-md ">
            <div className="flex w-full text-xs text-left bg-gray-50 font-bold text-gray-700 items-start justify-start px-4 py-2">
              <div style={{ width: "15%" }}>
                <h2>Company</h2>
              </div>
              <div className={`text-left flex-grow truncate w-1/2`}>
                <p>Job</p>
              </div>

              <div style={{ width: "10%" }}>Sponsor H1B?</div>

              <div style={{ width: "5%" }}>
                {!InternPage && <span>Yoe</span>}
              </div>

              <div style={{ width: "15%" }}>
                <svg
                  className="w-4 h-4 mr-2 text-gray-400 inline-flex"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Posted
              </div>
              <div style={{ width: "10%" }}>Action</div>
            </div>
          </div>
        )}

        <a
          href={job.apply_link ? job.apply_link : job.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleApplyNow(
              e,
              job.apply_link ? job.apply_link : job.link,
              job.id
            );
          }}
        >
          <div className="hidden lg:block shadow p-4 my-2 rounded-md ">
            <div className="sm:flex items-center items-start text-sm justify-start ">
              <div style={{ width: "15%" }}>
                <span className={`text-left`}>
                  <h2 className="ml-1 font-semibold text-base text-black">
                    {isApplied ? "✔️" : isViewed ? "👀" : "📌"}
                    <span style={{ paddingLeft: "8px" }}>{job.company}</span>
                  </h2>
                </span>
              </div>
              <div className={`text-left flex-grow truncate w-1/2`}>
                <p>{job.title}</p>
              </div>

              <div style={{ width: "10%" }}>
                <Tooltip
                  content={getH1BToolTip(isSponsorH1B, job.company)}
                  style="light"
                  placement="top"
                  className="text-xs"
                >
                  {getStatusBadge(isSponsorH1B, isApplied)}
                </Tooltip>
              </div>

              <div className={`flex-none`} style={{ width: "5%" }}>
                {isYOEInconsistencyReported && (
                  <Tooltip
                    content="Users say this YOE label might be wrong."
                    style="light"
                    placement="top"
                    className="text-xs"
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className={`text-xs font-medium mr-2 px-1.5 py-0.5 rounded ${"bg-purple-200 text-purple-800"}`}
                    >
                      ?
                    </span>
                  </Tooltip>
                )}
                {!isYOEInconsistencyReported && (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={`text-xs font-medium mr-2 px-1.5 py-0.5 rounded ${"bg-yellow-200 text-yellow-800"}`}
                  >
                    {job.yoe}
                  </span>
                )}
              </div>

              <div className={`flex-none`} style={{ width: "15%" }}>
                {isInvalidReported ? (
                  <Tooltip
                    content="Users say this job might be closed."
                    style="light"
                    placement="top"
                    className="text-xs"
                  >
                    <span className="px-1.5 py-0.5 rounded text-xs bg-gray-200 text-gray-700">
                      Closed
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    className={`px-1.5 py-0.5 rounded text-xs ${
                      getLabelColors(job.days).bgColor
                    } ${getLabelColors(job.days).textColor}`}
                  >
                    {job.postTime}
                  </span>
                )}
              </div>
              <div className="flex-none no-underline" style={{ width: "10%" }}>
                <ApplyButton
                  jobId={job.id}
                  onAppliedStatusChange={handleAppliedStatusChange}
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default JobItemUserPage;

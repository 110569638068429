import React, { useEffect } from "react";
import Header from "../layout/Header";
import Hero from "../layout/Hero";
import PMJobList from "./PMJobList";
import mixpanel from "mixpanel-browser";
import Footer from "../layout/Footer";
import { ViewedJobProvider } from "../user/ViewedJobsContext";
import { JobType } from "../JobType";

const PMPage = () => {
  useEffect(() => {
    mixpanel.track("PM Page Viewed");
  }, []);
  return (
    <ViewedJobProvider>
      <div>
        <Header />
        <Hero jobType={JobType.PRODUCT_FULL_TIME} />
        <PMJobList jobType={JobType.PRODUCT_FULL_TIME} />
        <Footer />
      </div>
    </ViewedJobProvider>
  );
};

export default PMPage;

import backgroundImage from "../img/background.png";
import desktopBanner from "../img/companyBanner.png";
import mobileBanner from "../img/companyBanner_mobile.png";
import CompanyModal from "../CompanyModal";
import { HandleLogin, onAuthChange } from "../user/User";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../utils/authContext";
import mixpanel from "mixpanel-browser";
import SubscribeCTA from "../subscribe/SubscribeCTA";
import JobAlertModal from "../jobs/JobAlertModal";
import { Tooltip } from "flowbite-react";
import { JobTypeDescription, JobType } from "../JobType";

const Hero = (jobType) => {
  const loggedIn = useContext(AuthContext);

  const [showJobWindow, setShowJobWindow] = useState(false);

  const handleToggleModalOpen = () => {
    mixpanel.track("Job Alert Open");
    setShowJobWindow(true);
  };

  const handleToggleModalClose = () => {
    mixpanel.track("Job Alert Close");
    setShowJobWindow(false);
  };

  return (
    <div className="mt-10">
      <section className="bg-center bg-no-repeat bg-blend-multiply bg-cover">
        <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-24">
          <h2 className="mb-4 text-4xl font-bold tracking-tight leading-normal text-black md:text-4xl lg:text-4xl">
            A Place for{" "}
            <span className="underline underline-offset-3 decoration-8 decoration-blue-500 dark:decoration-blue-600">
              {" "}
              Students{" "}
            </span>
            to Find Latest Jobs in Tech
          </h2>
          <p className="mb-8 text-lg font-medium text-gray-400 lg:text-xl sm:px-16 lg:px-48">
            {JobTypeDescription[jobType.jobType]}
          </p>
          <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-3 py-2 my-5 rounded-full dark:bg-blue-900 dark:text-blue-300">
            We are now tracking <CompanyModal /> top companies
          </span>
          <div className="justify-center flex items-center py-10">
            <JobAlertModal
              show={showJobWindow}
              onClose={handleToggleModalClose}
            />
            <Tooltip
              content="Subscribe for an ad-free, daily job report delivery"
              style="light"
              placement="bottom"
              className="text-xs"
            >
              <button
                href="#"
                className="inline-flex justify-center items-center py-2 px-5 text-base font-medium text-center text-white rounded-full bg-black hover:bg-gray-800 focus:ring-gray-600"
                onClick={() => {
                  mixpanel.track("Subscribe CTA");
                  handleToggleModalOpen();
                }}
              >
                Get Job Updates &rarr;
              </button>{" "}
            </Tooltip>
          </div>{" "}
          {/* <SubscribeCTA />
     

          {/* Desktop Image */}
          <img
            src={desktopBanner}
            alt="Desktop Banner"
            className="hidden sm:block w-full max-w-4xl mx-auto bg-center bg-no-repeat bg-blend-multiply pt-10"
          />
          {/* Mobile Image */}
          <img
            src={mobileBanner}
            alt="Mobile Banner"
            className="block sm:hidden w-full max-w-4xl mx-auto bg-center bg-no-repeat bg-blend-multiply pt-5"
          />
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4"></div>
          <div className="card-container flipped"></div>
        </div>
      </section>
    </div>
  );
};

export default Hero;

import React, { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import DoubleRangeSlider from "../DoubleRangeSlider/DoubleRangeSlider";
import { JobType } from "../JobType"; // Make sure to import JobType

const JobListFilter = ({
  InternPage,
  updateFilters,
  PMPage,
  DSPage,
  JobType,
  country,
  setCountry,
  rangeYoeValue,
  setRangeYoeValue,
}) => {
  const storageKey = `JobFilters-${JobType}`;

  // Function to load preferences from local storage or set default if not present

  // Load preferences or set to default values
  // const [rangeYoeValue, setRangeYoeValue] = useState(
  //   loadPreferences(`${storageKey}-rangeYoeValue`, 1)
  // );
  // const [country, setCountry] = useState(
  //   loadPreferences(`${storageKey}-country`, null)
  // );

  // Initialize selectCanada and selectUS based on the 'country' value
  const [selectCanada, setSelectCanada] = useState(
    country === "Canada" || country === null
  );
  const [selectUS, setSelectUS] = useState(
    country === "United States" || country === null
  );

  const handleCountry = () => {
    let newCountry;
    if ((selectCanada && selectUS) || (!selectCanada && !selectUS))
      newCountry = null;
    else if (selectCanada && !selectUS) newCountry = "Canada";
    else if (selectUS && !selectCanada) newCountry = "United States";

    setCountry(newCountry);
  };

  const savePreferences = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const handleCheckBoxChange = (event) => {
    mixpanel.track("Country Check");
    const { id, checked } = event.target;

    if (id === "us-checkbox") {
      setSelectUS(checked);
    } else if (id === "canada-checkbox") {
      setSelectCanada(checked);
    }
  };

  const handleRangeChange = (event) => {
    mixpanel.track("Yoe Range");
    setRangeYoeValue(event.target.value);
  };

  const displayRangeYoeValue = (yoe) => {
    if (PMPage || DSPage) {
      return `<=${yoe}`;
    }
    if (yoe == 0 || PMPage || DSPage) {
      return yoe;
    } else if (yoe >= 1 && yoe <= 3) {
      return `<=${yoe}`;
    } else if (yoe == 4) {
      return "<=5";
    }
    // Add a default return statement or handle other cases if needed
  };

  useEffect(() => {
    updateFilters(country, rangeYoeValue);
    savePreferences(`${storageKey}-rangeYoeValue`, rangeYoeValue);
    savePreferences(`${storageKey}-country`, country);
    handleCountry();
  }, [country, rangeYoeValue, selectUS, selectCanada]);

  return (
    <div>
      <div className="hidden md:flex dfflex items-center justify-between max-w-4xl mx-auto gap-3 mb-3 mt-8">
        <div className="flex items-center ">
          <input
            id="us-checkbox"
            type="checkbox"
            checked={selectUS}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
            onChange={handleCheckBoxChange}
          />
          <label
            htmlFor="us-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            United States
          </label>
          <input
            checked={selectCanada}
            id="canada-checkbox"
            type="checkbox"
            className="ml-3 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
            onChange={handleCheckBoxChange}
          />
          <label
            htmlFor="canada-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Canada
          </label>
        </div>
        {/* <div className="text-sm">
          <DoubleRangeSlider
            min={0}
            max={5}
            onChange={({ min, max }) =>
              console.log(`min = ${min}, max = ${max}`)
            }
          />
        </div> */}
        {!InternPage && (
          <div className="flex items-center">
            <label
              htmlFor="minmax-range"
              className=" text-sm font-medium text-gray-900 dark:text-white"
            >
              Year of Experience:
            </label>
            <span className="ml-2 text-sm text-gray-900 dark:text-gray-300">
              {displayRangeYoeValue(rangeYoeValue)}
            </span>
            <input
              id="minmax-range"
              type="range"
              min={0}
              max={PMPage || DSPage ? 10 : 4} // Update the maximum value based on PMPage
              value={rangeYoeValue}
              onChange={handleRangeChange}
              disabled={InternPage === true}
              className="ml-2 mb-1 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default JobListFilter;

import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal } from "flowbite-react";
import logo from "../img/logo-light.png";
import "../style/header.css";
import { useGlobalState } from "../state";
import mixpanel from "mixpanel-browser";
import { HandleLogOut, HandleLogin } from "../user/User";
// import ContactUs from "./ContactUs";
import AuthContext from "../utils/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import discord_icon from "../img/discord_icon.png";
import ProfilePlaceholder from "../../src/img/profile_placeholder.png";
import google_logo from "../img/Google_logo.png";
import { Tooltip } from "flowbite-react";
import JobAlertModal from "../jobs/JobAlertModal";

const PostHeader = () => {
  const loggedIn = useContext(AuthContext);
  const userName = useGlobalState("user_name");
  const photoURL = useGlobalState("user_photoURL")[0];

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dropdownRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsProfileMenuOpen(false);
    }
  };

  const [showJobWindow, setShowJobWindow] = useState(false);
  const [key, setKey] = useState(Date.now());
  // const [showAlert, setShowAlert] = useState(false);

  const handleToggleModalOpen = () => {
    mixpanel.track("Job Alert Open");
    setKey(Date.now());
    setShowJobWindow(!showJobWindow);
  };

  const handleToggleModalClose = () => {
    mixpanel.track("Job Alert Close");
    setShowJobWindow(false);
  };

  const location = useLocation();
  const isInternshipPage =
    location.pathname === "/Software-Engineer-Internship";

  const isFullTimePage =
    location.pathname === "/" ||
    location.pathname === "/Software-Engineer-NewGrad";

  const isProductPage = location.pathname === "/Product-Manager";
  const isProductInternPage =
    location.pathname === "/Product-Manager-Internship";

  const isPartnershipPage = location.pathname === "/JoinUs";

  const HandleDiscord = () => {
    mixpanel.track("Open Discord Link");
  };

  useEffect(() => {
    if (isProfileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProfileMenuOpen]);

  return (
    <div>
      <JobAlertModal show={showJobWindow} onClose={handleToggleModalClose} />
      <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="https://jobpulse.fyi/" className="flex items-center">
            <img src={logo} className="h-8 mr-3" alt="JobPulse Logo" />
            <p className="appTitle ml-2">JobPulse</p>
          </a>
          <div className="flex md:order-2">
            <div className="mr-2">
              {windowWidth >= 400 && (
                <button
                  type="button"
                  onClick={handleToggleModalOpen}
                  className=" sm:inline-block text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-semibold rounded-full text-sm px-3 py-1 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  🔔 Job Alert
                </button>
              )}
            </div>
            {!loggedIn && (
              <div>
                <Tooltip
                  content="Zero Ads! Log In to Better Track & Mark Jobs!"
                  style="light"
                  placement="bottom"
                  className="text-xs"
                >
                  <button
                    className="flex items-center inline-block text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-full text-sm px-3 py-1 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    onClick={() => {
                      mixpanel.track("Header Login");
                      HandleLogin();
                    }}
                  >
                    <img src={google_logo} className="w-4 h-4 mr-2" /> Log In
                    &rarr;
                  </button>
                </Tooltip>
              </div>
            )}
            {loggedIn && (
              <div
                className="relative"
                onMouseEnter={() => setIsProfileMenuOpen(true)}
                onMouseLeave={(e) => {
                  // Check if the cursor is not over the menu or profile picture
                  if (
                    !dropdownRef.current?.contains(e.target) &&
                    !e.currentTarget.contains(e.relatedTarget)
                  ) {
                    setIsProfileMenuOpen(false);
                  }
                }}
              >
                <div className="relative w-7 h-7 rounded-full">
                  <img
                    className="w-7 h-7 rounded-full cursor-pointer"
                    src={photoURL ? photoURL : ProfilePlaceholder}
                    alt="User Profile"
                  />
                </div>
                {isProfileMenuOpen && (
                  <div
                    ref={dropdownRef}
                    id="dropdown"
                    className="z-10 absolute top-full left-1/2 transform -translate-x-1/2 bg-white divide-y divide-gray-100 rounded-lg shadow w-36 dark:bg-gray-700"
                  >
                    <ul
                      className="py-2 text-xs font-medium text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li>
                        <div
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={handleToggleModalOpen}
                        >
                          Job Alert
                        </div>
                      </li>
                      <li>
                        <div
                          className="block px-4 text-red-500 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={HandleLogOut}
                        >
                          Log Out
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-16 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="/"
                  className={`block py-2 pl-3 pr-4 ${
                    isFullTimePage
                      ? "text-white bg-blue-700 md:bg-transparent md:text-blue-700"
                      : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                  } rounded md:p-0`}
                  aria-current="page"
                >
                  Explore
                </a>
              </li>
              <li>
                <a
                  href="/post"
                  className={`block py-2 pl-3 pr-4 ${
                    isInternshipPage
                      ? "text-white bg-blue-700 md:text-blue-700 md:bg-transparent"
                      : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                  } rounded md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  Community
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className={`block py-2 pl-3 pr-4 ${
                    isProductPage
                      ? "text-white bg-blue-700 md:text-blue-700 md:bg-transparent"
                      : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                  } rounded md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  Jobs
                </a>
              </li>
              <li>
                <a
                  href="https://buymeacoffee.com/gerogialin"
                  className={`block py-2 pl-3 pr-4 ${
                    isProductInternPage
                      ? "text-white bg-blue-700 md:text-blue-700 md:bg-transparent"
                      : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                  } rounded md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  Buy us a coffee
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/R6rWBFhzF2"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center block py-2 pl-3 pr-4 text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700
                    rounded md:p-0 md:dark:hover:text-blue-500"
                  onClick={HandleDiscord}
                >
                  {/* <img src={discord_icon} className="w-4 h-4 mr-2" /> */}
                  Discord
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default PostHeader;

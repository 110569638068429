import React, { useEffect } from "react";
import Header from "../layout/Header";
import Hero from "../layout/Hero";
import PartnerBlog from "./PartnerBlog";

import mixpanel from "mixpanel-browser";
import Footer from "../layout/Footer";

const PartnershipPage = () => {
  useEffect(() => {
    mixpanel.track("Partnership Page Viewed");
  }, []);
  return (
    <div>
      <Header />
      <PartnerBlog />
      <Footer />
    </div>
  );
};

export default PartnershipPage;

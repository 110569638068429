import { useState, useEffect, useRef } from "react";
import { Modal } from "flowbite-react";
import { createPost } from "./Post.js";
import mixpanel from "mixpanel-browser";

const PostModal = ({ show, onClose }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [content, setContent] = useState("");

  const companyRef = useRef(null);
  const jobTitleRef = useRef(null);
  const postTitleRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (show) {
      // Reset the form when the modal is opened
      if (companyRef.current) companyRef.current.value = "";
      if (jobTitleRef.current) jobTitleRef.current.value = "";
      if (postTitleRef.current) postTitleRef.current.value = "";
      if (contentRef.current) contentRef.current.value = "";
    }
  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = {
      company: companyRef.current?.value || "",
      job_title: jobTitleRef.current?.value || "",
      post_title: postTitleRef.current.value,
      content: contentRef.current.value,
      category: "Job Progress", // default value
    };
    try {
      await createPost(newPost);
      console.log("Post created successfully");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false); // Hide the alert after 3 seconds
        onClose(); // Close the modal after submission
      }, 3000);
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const renderSuccessAlert = () => {
    return (
      <div
        className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
        role="alert"
      >
        <span className="font-medium">Thank you!</span> Your post has been
        created successfully.
      </div>
    );
  };

  return (
    <div>
      <div>{showAlert && renderSuccessAlert()}</div>
      <Modal show={show} onClose={onClose}>
        <Modal.Header>📝 Create a New Post</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Company (optional)
              </label>
              <input
                type="text"
                id="company"
                name="company"
                ref={companyRef}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Company"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="jobTitle"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Job Title (optional)
              </label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                ref={jobTitleRef}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Job Title"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="postTitle"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Post Title
              </label>
              <input
                type="text"
                id="postTitle"
                name="postTitle"
                ref={postTitleRef}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Post Title"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="content"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Content
              </label>
              <textarea
                id="content"
                name="content"
                ref={contentRef}
                rows="4"
                className="block mb-8 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Write your thoughts here..."
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PostModal;

import React, { useState } from "react";
import CompanySearch from "../search/CompanySearch";
import "../style/header.css";
import db, { firestore } from "../utils/firebase";
import { collection, addDoc } from "firebase/firestore";
import Header from "../layout/Header";
import "firebase/firestore";
import { useGlobalState } from "../state";
import { httpsCallable } from "@firebase/functions";
import { getFunctions } from "firebase/functions"; // Import getFunction
import { getFirestore, Timestamp } from "firebase/firestore";
import CompanySelectOne from "../search/CompanySelectOne";

const AddJob = () => {
  // Define the initial state for form fields
  const user_id = useGlobalState("user_id");
  const [applyLink, setApplyLink] = useState("");
  const [category, setCategory] = useState("Software Engineer");
  const [company, setCompany] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("United States");
  const [title, setTitle] = useState("Software Engineer");
  const [yoe, setYoe] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const SWEJobsCollection = collection(db, "linkedin_jobs");
  const PMJobsCollection = collection(db, "linkedin_pm_jobs");
  const DSJobsCollection = collection(db, "linkedin_ds_jobs");
  const UserUploadJobCollection = collection(db, "user_uploaded_jobs");

  const functions = getFunctions(); // Initialize Firebase functions

  const makeUserAdmin = (uid) => {
    console.log(uid);
    const functions = getFunctions(); // Initialize Firebase functions
    const setAdminRoleFunction = httpsCallable(functions, "setAdminRole");
    setAdminRoleFunction({ uid: uid })
      .then((result) => {
        console.log(result.data);
        console.log(result.data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Form submission handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("category", category);
    console.log("location", location);

    if (!user_id) {
      console.error("User must be logged in to add a job.");
      return;
    }

    if (category === null || location === null) {
      console.error("Category or Location cannot be null.");
      return;
    }

    if (!company) {
      console.error("Company cannot be null.");
      setErrorMessage("Please choose one company");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }

    // Automatically set the time to the current time if it's not set
    let currentTime = time;
    if (!currentTime) {
      const now = new Date();
      currentTime = now.toTimeString().slice(0, 5); // Get current time in HH:MM format
      setTime(currentTime);
    }

    let processedYoe = yoe !== "intern" ? parseInt(yoe) : yoe;

    const now = new Date();
    const firestoreTimestamp = Timestamp.fromDate(now);

    const jobData = {
      apply_link: applyLink,
      category: category,
      company: company,
      date_added: firestoreTimestamp,
      location: location,
      title: title,
      title_correct_by_gpt: true,
      yoe: processedYoe,
      upload_user: user_id[0],
      approved: null,
    };

    console.log(jobData);

    try {
      let JobRef = await addDoc(UserUploadJobCollection, jobData);
      console.log(JobRef);
      console.log("Job added successfully!");

      // Clear form fields
      setApplyLink("");
      setCategory("Software Engineer");
      setCompany("");
      setDate("");
      setTime("");
      setLocation("United States");
      setTitle("Software Engineer");
      setYoe(0);

      // Display success message
      setSuccessMessage(`👏 ${title} @${company} is added successfully!`);
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error adding job: ", error);
      setErrorMessage(`Error: ${error.message}`);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  const handleSelectedCompanyChange = (selectedCompany) => {
    setCompany(selectedCompany);
    console.log(selectedCompany);
  };

  return (
    <div>
      <Header />
      <div className="max-w-4xl mx-auto">
        <div className="pt-20">
          <p className="text-2xl text-gray-900 font-semibold dark:text-white my-8">
            🙌 Share Job Openings with the Community
          </p>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-4 gap-4">
              <div className="mb-4 col-span-2">
                <label
                  htmlFor="title"
                  className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                >
                  Company
                </label>
                <CompanySelectOne
                  onSelectedCompanyChange={handleSelectedCompanyChange}
                />
              </div>
              <div className="mb-4 col-span-2">
                <label
                  htmlFor="title"
                  className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                >
                  Job Title
                </label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="mb-4 col-span-2">
                <label
                  htmlFor="category"
                  className="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white"
                >
                  Category:
                </label>
                <select
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="Software Engineer">Software Engineer</option>
                  <option value="Product Manager">Product Manager</option>
                  {/* <option value="Data Scientist">Data Scientist</option> */}
                </select>
              </div>
              <div className="mb-4 col-span-2">
                <label
                  htmlFor="yoe"
                  className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                >
                  Years of Experience
                </label>
                <select
                  id="yoe"
                  value={yoe}
                  onChange={(e) => setYoe(e.target.value)}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="intern">Intern</option>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>
              <div className="col-span-2 mb-4">
                <label
                  htmlFor="apply_link"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
                >
                  Apply Link
                </label>
                <input
                  type="url"
                  id="apply_link"
                  value={applyLink}
                  onChange={(e) => setApplyLink(e.target.value)}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="col-span-2 "></div>
              <div className="mb-4 col-span-2">
                <label
                  htmlFor="location"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
                >
                  Country:
                </label>
                <select
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                </select>
              </div>

              <div className=" flex my-4 col-span-4">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-12 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Add Job
                </button>
              </div>
            </div>
          </form>
          {successMessage && (
            <p className="text-green-500 text-sm mt-4 text-left">
              {successMessage}
            </p>
          )}
          {errorMessage && (
            <p className="text-red-500 text-sm mt-4 text-left">
              ❗️{errorMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddJob;

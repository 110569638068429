import React, { useState, useEffect } from "react";
import { addComment, fetchPostComments } from "./Post";
import CommentList from "./CommentList"; // Import the CommentList component

const PostItem = ({
  company,
  postTitle,
  username,
  content,
  comments_count,
  likes_count,
  jobTitle,
  post_time,
  postId, // Ensure you pass postId as a prop
}) => {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentContent, setCommentContent] = useState("");
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(comments_count);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const { comments, commentCount } = await fetchPostComments(postId);
        setComments(comments);
        setCommentCount(commentCount);
        console.log(comments);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [postId]);

  const handleCommentClick = () => {
    setShowComments(!showComments);
  };

  const handleReplyClick = () => {
    setShowCommentBox(!showCommentBox);
  };

  const handleAddComment = async () => {
    try {
      const data = await addComment(commentContent, postId);
      setCommentContent("");
      setShowCommentBox(false);
      // Fetch the updated comments after adding a new comment
      const { comments, commentCount } = await fetchPostComments(postId);
      setComments(comments);
      setCommentCount(commentCount);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  return (
    <div className="m-4 border-b border-gray-300">
      <div className="pb-4 text-left">
        <h4>
          <span className="text-blue-600">{company}</span>
          <span className="text-gray-400"> {jobTitle}</span>
        </h4>
        <p>
          <strong>{postTitle} </strong>
          <span className="text-blue-600">@{username}</span>
          <br />
          {content}
        </p>
        <div className="flex items-center justify-between mt-4">
          <div className="flex space-x-4">
            <span
              className="text-sm flex items-center cursor-pointer"
              onClick={handleCommentClick}
            >
              💬 {commentCount}
            </span>
            <span className="text-sm flex items-center">👀 {likes_count}</span>
          </div>
          <div className="flex space-x-2">
            <span
              className="text-sm text-gray-500 flex items-center cursor-pointer"
              onClick={handleReplyClick}
            >
              ✍️ reply
            </span>
          </div>
        </div>
        {showCommentBox && (
          <form className="mt-4">
            <label htmlFor="chat" className="sr-only">
              Your message
            </label>
            <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
              <textarea
                id="chat"
                rows="1"
                className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your message..."
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
              ></textarea>
              <button
                type="button"
                onClick={handleAddComment}
                className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
              >
                <svg
                  className="w-5 h-5 rotate-90 rtl:-rotate-90"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 20"
                >
                  <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                </svg>
                <span className="sr-only">Send message</span>
              </button>
            </div>
          </form>
        )}
        {showComments && <CommentList comments={comments} />}
      </div>
    </div>
  );
};

export default PostItem;

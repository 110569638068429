import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useGlobalState } from "../state";
import db from "../utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import JobItemUserPage from "../jobs/JobItemUserPage";
import UserDashboard from "./UserDashboard";
import { calculatePostTime } from "../jobs/Job";
import { API_BASE_URL } from "../utils/config";

const ViewedJobList = () => {
  const [userID, loggedIn] = [
    useGlobalState("user_id"),
    useGlobalState("user_logedIn"),
  ];

  const [activeTab, setActiveTab] = useState("applied");
  const [companyH1BMap, setCompanyH1BMap] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [jobsDetails, setJobsDetails] = useState({
    applied: [],
    viewed: [],
  });

  const limit = 20;

  useEffect(() => {
    const fetchCompanyData = async () => {
      const CompanyMapRef = doc(db, "all_companies", "company_map");
      const MapSnap = await getDoc(CompanyMapRef);

      if (MapSnap.exists()) {
        setCompanyH1BMap(MapSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchCompanyData();
  }, []);

  const CompanyH1BBefore = (company_name) => {
    return (
      companyH1BMap?.company_map?.[company_name]?.sponsored_h1b_before || null
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userID[0] && loggedIn[0]) {
        // Ensure the user is logged in
        setLoading(true);

        const newJobsData = await fetchJobs(activeTab, page, limit);

        setJobsDetails((prevData) => ({
          ...prevData,
          [activeTab]: [...prevData[activeTab], ...newJobsData],
        }));

        setLoading(false);
      }
    };

    fetchData();
  }, [page, activeTab, userID[0], loggedIn[0]]);

  useEffect(() => {
    // console.log(jobsDetails[activeTab].length);
    // console.log(jobsDetails[activeTab]);
  }, [jobsDetails]);

  const fetchJobs = async (type, page, limit) => {
    const idToken = localStorage.getItem("idToken");
    const uid = localStorage.getItem("uid");

    try {
      const url = `${API_BASE_URL}/${
        type === "applied" ? "applied_jobs" : "viewed_jobs"
      }?uid=${uid}&page_number=${page}&page_size=${limit}`;

      // console.log(url);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      return data.job_details.map((job) => {
        const { days, postTime } = calculatePostTime(job.date_added);

        return { id: job.job_id, ...job, days, postTime };
      });
    } catch (error) {
      console.error("Error fetching jobs:", error);
      return [];
    }
  };

  const loadMore = async () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div>
      <div className="max-w-4xl mx-auto py-48">
        <UserDashboard />
        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          <li className="mr-2">
            <div
              onClick={() => {
                mixpanel.track("Applied Job Tab");
                setActiveTab("applied");
              }}
              className={`inline-block p-4 rounded-t-lg ${
                activeTab === "applied"
                  ? "text-blue-600 bg-gray-100 dark:bg-gray-800 dark:text-blue-500"
                  : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              }`}
            >
              Applied Jobs
            </div>
          </li>

          <li className="mr-2">
            <div
              onClick={() => {
                mixpanel.track("Viewed Job Tab");
                setActiveTab("viewed");
              }}
              className={`inline-block p-4 rounded-t-lg ${
                activeTab === "viewed"
                  ? "text-blue-600 bg-gray-100 dark:bg-gray-800 dark:text-blue-500"
                  : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              }`}
            >
              Viewed Jobs
            </div>
          </li>
        </ul>

        {/* Conditionally render the content based on the activeTab */}

        <div>
          {jobsDetails[activeTab]?.map((job, index) => (
            <JobItemUserPage
              key={index}
              job={job}
              isSponsorH1B={CompanyH1BBefore(job.company)}
              firstOne={index === 0}
              InternPage={false}
              PMPage={job.IsPMJob}
            />
          ))}
        </div>
        {/* {activeTab === "applied" && (
          <div>
            {appliedJobsDetails &&
              appliedJobsDetails.map((job, index) => (
                <div key={job.id || index}>
                  <JobItemUserPage
                    job={job}
                    isSponsorH1B={CompanyH1BBefore(job.company)}
                    firstOne={index === 0}
                    InternPage={false}
                    PMPage={job.IsPMJob}
                  />
                </div>
              ))}
          </div>
        )}

        {activeTab === "viewed" && (
          <div>
            {" "}
            <ul>
              {viewedJobsDetails &&
                viewedJobsDetails.map((job, index) => (
                  <div key={job.id || index}>
                    <JobItemUserPage
                      job={job}
                      isSponsorH1B={CompanyH1BBefore(job.company)}
                      firstOne={index === 0}
                      InternPage={false}
                      PMPage={job.IsPMJob}
                    />
                  </div>
                ))}
            </ul>
          </div>
        )} */}
        {loading && (
          <div className="flex items-center justify-center p-8 ">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>

      {!loading && (
        <button
          className="mt-10 py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          onClick={loadMore}
          disabled={loading}
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default ViewedJobList;

import React, { useState, useEffect } from "react";
import { getDoc, doc, collection } from "firebase/firestore";
import db from "../utils/firebase";
import mixpanel from "mixpanel-browser";

function CompanySelection({ onSelectedCompanyChange, defaultCompanies }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false); // set default state to false
  const [placeholderText, setPlaceholderText] = useState("all companies");

  const companiesCollectionRef = collection(db, "all_companies");

  const companiesDocRef = doc(db, "all_companies", "company_list");
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        // Fetch the specific document using the DocumentReference
        const data = await getDoc(companiesDocRef);
        // Check if document exists
        if (data.exists()) {
          const fetchedData = data.data().company_list;
          setCompanies(fetchedData);
          setSelectedCompanies(fetchedData);
        } else {
          console.error("No such document!");
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchCompanyData();
  }, []);

  useEffect(() => {
    // console.log("defualt compnaies", defaultCompanies);
    if (defaultCompanies) {
      setSelectedCompanies(defaultCompanies);
      if (defaultCompanies.length == companies.length) {
        setPlaceholderText("all companies");
      } else {
        setPlaceholderText(`${defaultCompanies.length}  companies`);
        setSelectAll();
      }
    }
  }, [defaultCompanies]);

  useEffect(() => {
    // console.log("companies", companies);
    const results = companies
      .filter((company) =>
        company.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    setFilteredCompanies(results);
  }, [searchTerm, companies]);

  useEffect(() => {
    if (selectAll) {
      setSelectedCompanies(companies);
    } else {
      setSelectedCompanies([]);
    }
  }, [selectAll, companies]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckChange = (company, isChecked) => {
    setSelectedCompanies((prevCompanies) => {
      if (isChecked) {
        return [...prevCompanies, company];
      } else {
        return prevCompanies.filter((item) => item !== company);
      }
    });
  };

  const handleSelectAllChange = (isChecked) => {
    setSelectAll(isChecked);
  };

  const handleConfirm = () => {
    setShowDropdown(false); // hide the dropdown when confirmed
    onSelectedCompanyChange(selectedCompanies);

    if (
      selectedCompanies.every((item) => companies.includes(item)) &&
      selectedCompanies.length === companies.length
    ) {
      setPlaceholderText("all companies");
    } else {
      setPlaceholderText(`${selectedCompanies.length}  companies`);
    }
  };

  return (
    <div className="pl-3">
      <div>
        <div className="relative">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <input
            type="text"
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 placeholder-blue-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-3 p-1"
            placeholder={placeholderText}
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={() => setShowDropdown(true)} // show dropdown when input is focused
          />
          {showDropdown && (
            <div
              className="absolute z-10 w-full"
              style={{ maxHeight: "240px" }} // total of dropdown and button height
            >
              <div
                className="relative text-gray-700 text-sm bg-white border rounded shadow overflow-y-auto text-left"
                style={{ maxHeight: "200px", paddingBottom: "40px" }}
              >
                <div className="p-2">
                  <input
                    className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="checkbox"
                    checked={selectAll}
                    onChange={(e) => handleSelectAllChange(e.target.checked)}
                  />
                  <label>all companies</label>
                </div>
                {filteredCompanies.map((company, index) => (
                  <div key={index} className="p-2">
                    <input
                      className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      type="checkbox"
                      checked={selectedCompanies.includes(company)}
                      onChange={(e) =>
                        handleCheckChange(company, e.target.checked)
                      }
                    />
                    <label>{company}</label>
                  </div>
                ))}
              </div>
              <button
                className="relative w-full py-2 text-sm text-center border border-gray-300 text-blue-500 bg-white rounded hover:text-blue-700 hover:bg-gray-100"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanySelection;
